import React, { useEffect, useState } from "react";
import {
  deleteUserRoles,
  getUserList,
  getUserRoles,
  manageRoles,
} from "../../redux/actionCreator";
import { API_BASE_URL } from "../../constants/configuration";
import PaginationRow from "../../components/PaginationRow/PaginationRow";
import Table from "../../components/Table/Table";
import Modal from "../../Tools/Modal/Modal";
import FormSearch from "../../components/FormSearch/FormSearch";
import { capitalize } from "../../utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  role_page_status,
  rolesTotalCount,
  userRoles,
  role_loader,
  updateRedux,
} from "../../redux/commonReducer";
import ReactPaginate from "react-paginate";
import {
  WORD_ACTION,
  WORD_ACTIONS,
  WORD_ADD_ROLE,
  WORD_ASSIGN_ROLE,
  WORD_CANCEL,
  WORD_CONFIRM,
  WORD_DO_YOU_WANT_TO_DELETE,
  WORD_NAME,
  WORD_ROLES_ADDED_BY,
  WORD_ROLE_DELETED_SUCCESFULLY,
  WORD_SUBUNIT,
  WORD_UNIT,
  WORD_USERNAME,
  WORD_SELECT_RANK,
  WORD_SELECT_UNIT,
  WORD_SELECT_SUBUNIT,
  WORD_EMPLOYEE_ID,
  WORD_RANK,
} from "../../constants/translation";
import CommonSearch from "../../components/FormSearch/CommonSearch";
import Pagination from "../../components/CommonPagination/Pagination";
// import { Button, Col, Form, Table } from "react-bootstrap";

export default function AssignRole() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const roles = useSelector(userRoles);
  const pageCount = useSelector(rolesTotalCount);
  const page_status = useSelector(role_page_status);
  const loader = useSelector(role_loader);

  const [data, setData] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(0);
  const [deleteId, setDeleteID] = useState("");
  const [modalshow, setModalShow] = useState(false);
  const [limit, setLimit] = useState(10);
  const [rank, setRank] = useState({ rank: WORD_SELECT_RANK });
  const [unit, setUnit] = useState({ unit_name: WORD_SELECT_UNIT });
  const [subUnit, setSubUnit] = useState({ subunit_name: WORD_SELECT_SUBUNIT });
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState("createdAt");

  const handleClose = () => setModalShow(false);

  let Titles = [
    { title: "Sl.No", key: "index" },
    // { title: WORD_NAME, key: "name", sort: "name" },
    // { title: WORD_USERNAME, key: "username", sort: "username" },
    // { title: WORD_UNIT, key: "unit", sort: "unit" },
    // { title: WORD_SUBUNIT, key: "subunit", sort: "subunit" },
    { title: WORD_EMPLOYEE_ID, key: "employee_id", sort: "employee_id" },
    { title: WORD_RANK, key: "rank", sort: "rank" },
    { title: WORD_NAME, key: "name", sort: "name" },
    { title: WORD_UNIT, key: "unit", sort: "unit" },
    { title: WORD_SUBUNIT, key: "subunit", sort: "subunit" },
    { title: WORD_ROLES_ADDED_BY, key: "createdby", sort: "createdBy" },
    { title: WORD_ACTIONS, key: "handler" },
  ];

  useEffect(() => {
    let formData = new FormData();
    formData.append("unit", "");
    formData.append("subunit", "");
    formData.append("rank", "");
    formData.append("page", 0);
    formData.append("perPage", limit);
    formData.append("keyword", searchKey);
    formData.append("sort_key", sortColumn);
    formData.append("sort_order", -1);
    dispatch(updateRedux({ key: "role_loader", value: true }));
    dispatch(
      getUserRoles(formData, (res) => {
        dispatch(updateRedux({ key: "role_loader", value: false }));
      })
    );
  }, []);

  useEffect(() => {
    if (roles) {
      setData(
        roles?.map((d, i) => {
          return {
            index: i + 1,
            _id: d._id,
            name: d.name,
            username: d.username,
            unit: d.unit[0]?.unit_name,
            subunit: d.subunit[0]?.subunit_name,
            is_secretery: d.is_secretery,
            createdby: d.roles_added_by[0]?.name,
            employee_id : d?.employee_id,
            rank : d?.rank[0]?.rank,
            handler: (
              <div className="d-flex align-items-center justify-content-right">
                 <img
                  className="eye"
                  src="./assets/icons/edit.svg"
                  alt=""
                  onClick={() => editUserRole(d)}
                />
                <img
                  className="delete"
                  src="./assets/icons/delete1.svg"
                  alt=""
                  onClick={() => handleRemove(d._id)}
                />
              </div>
            ),
          };
        })
      );
    }
  }, [roles]);

  const handleRemove = (id) => {
    setModalShow(true);
    setDeleteID(id);
  };

  const editUserRole = (ele) => {
    navigate(`/manage_user_roles/edit_role/${ele._id}`);
  };

  const deleteUserRole = () => {
    dispatch(
      deleteUserRoles(deleteId, page, searchKey, (status) => {
        if (status) {
          let formData = new FormData();
          formData.append("unit", unit._id ?? "");
          formData.append("subunit", subUnit._id ?? "");
          formData.append("rank", rank?._id ?? "");
          formData.append("page", 0);
          formData.append("perPage", limit);
          formData.append("keyword", searchKey);
          formData.append("sort_key", sortColumn);
          formData.append("sort_order", sortAscending ? 1 : -1);
          dispatch(getUserRoles(formData));
          setModalShow(false);
          toast.success(WORD_ROLE_DELETED_SUCCESFULLY, {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  const onLimitChangeHandler = (limitValue) => {
    if (limit != limitValue) {
      setLimit(limitValue);
      setPage(0);
      let formData = new FormData();
      formData.append("unit", unit._id ?? "");
      formData.append("subunit", subUnit._id ?? "");
      formData.append("rank", rank?._id ?? "");
      formData.append("page", 0);
      formData.append("perPage", limitValue);
      formData.append("keyword", searchKey);
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      dispatch(getUserRoles(formData));
    }
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("unit", unit._id ?? "");
      formData.append("subunit", subUnit._id ?? "");
      formData.append("rank", rank?._id ?? "");
      formData.append("page", e.selected);
      formData.append("perPage", limit);
      formData.append("keyword", searchKey);
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      dispatch(getUserRoles(formData));
    }
  };

  const onSortHandler = (type) => {
    setSortAscending(!sortAscending);
    setSortColumn(type);
    let formData = new FormData();
    formData.append("unit", unit._id ?? "");
    formData.append("subunit", subUnit._id ?? "");
    formData.append("rank", rank?._id ?? "");
    formData.append("page", page);
    formData.append("perPage", limit);
    formData.append("keyword", searchKey);
    formData.append("sort_key", type);
    formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getUserRoles(formData));
  };

  return (
    <div id="AssignRole">
      <h1 className="page-title">{WORD_ASSIGN_ROLE}</h1>
      <span className="button text-left">
        <button
          className="btn btn-primary"
          onClick={() => navigate("/manage_user_roles/add_role")}
        >
          {WORD_ADD_ROLE}
        </button>
      </span>
      <section>
        <CommonSearch
          pageName = "role"
          page={page}
          setPage={setPage}
          limit={limit}
          getData={getUserRoles}
          rank={rank}
          setRank={setRank}
          unit={unit}
          setUnit={setUnit}
          subUnit={subUnit}
          setSubUnit={setSubUnit}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          setSortAscending={setSortAscending}
          setSortColumn={setSortColumn}
        />
      </section>
      <section>
        <PaginationRow
          page={"assignrole"}
          onCount={(count) => onLimitChangeHandler(count)}
        />
      </section>
      <section>
        {loader ? (
          <div className="loader-div">
            <img
              className="circles-loader"
              src="/assets/loader/circles.svg"
              alt=""
            />
          </div>
        ) : (
          <>
            <Table
              page={"role"}
              titles={Titles}
              data={data}
              onSortHandler={onSortHandler}
              sortColumn={sortColumn}
              sortAscending={sortAscending}
            />
            <div className="align-between">
              <p style={{ direction: "ltr", color: "#0d7b58" }}>
                {page_status}
              </p>
              <Pagination
                page={page}
                pageCount={pageCount}
                handlePageChange={handlePageChange}
              />
            </div>
          </>
        )}
      </section>
      <Modal show={modalshow} centered onHide={handleClose}>
        <div id="CheckBoxModal">
          <h3 dir="ltr">{WORD_DO_YOU_WANT_TO_DELETE}</h3>
          <div className="buttons">
            <button className="confirm" onClick={deleteUserRole}>
              {WORD_CONFIRM}
            </button>
            <button className="cancel" onClick={handleClose}>
              {WORD_CANCEL}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
