import React, { useContext, useState } from "react";
import "./ButtonsFooter.scss";
import { Link } from "react-router-dom";
import { RequestInputContext } from "../../pages/ManageRequests/CreateRequest";
import {
  WORD_ADD_ATTACHMENT,
  WORD_CANCEL,
  WORD_NEXT,
  WORD_BACK,
  WORD_NOT_PDF_FILE,
  WORD_FILE_UPLOAD_SUPPORTED_FORMATS,
  WORD_ADD_SUPPORTING_DOCS,
} from "../../constants/translation";
import { useSelector } from "react-redux";
import { loader } from "../../redux/commonReducer";
import { useEffect } from "react";

export default function ButtonsFooter({
  page,
  onCancel,
  onNext,
  onSave,
  onBack,
  inputs,
  setInputs,
}) {

  const loader1 = useSelector(s => s.commonReducer.loader1)

  const flag =
    page === "RequestDetailsEntry"
      ? 1
      : page === "AssignSignatory"
        ? 2
        : page === "SignatoriesEntry"
          ? 3
          : 0;

  let inputErrors, setInputErrors, initialInputObject;
  const CreateRequestContextValues = useContext(RequestInputContext);
  if (CreateRequestContextValues) {
    ({ inputs, setInputs, inputErrors, setInputErrors, initialInputObject } =
      CreateRequestContextValues);
  }

  function fileDeleteHandler(file) {
    let filteredFiles = inputs.supportingFiles.filter(item => {
      return item !== file
    })
    setInputs(s => ({ ...s, supportingFiles: filteredFiles }))
  }

  return (
    <div id="ButtonsFooter">
      {flag === 1 ? (
        <>
          <span className="attachment-button">
            <label className="attachment-button" htmlFor="file-attach-button">
              {
                inputs?.pdf && (
                  <span className="attached-file">
                    {
                      typeof inputs?.pdf === 'object' ?
                        (
                          inputs?.pdf?.type === "application/pdf"
                            ?
                            <>
                              <img src="./assets/images/pdf.png" alt="" />
                              <p>{inputs?.pdf?.name?.substring(0, 14)}</p>
                            </>
                            : <p style={{ color: "red" }}>{WORD_NOT_PDF_FILE}</p>
                        )
                        : typeof inputs?.pdf === 'string' &&
                        <>
                          <img src="./assets/images/pdf.png" alt="" />
                          <p>{inputs?.pdf?.substring(0, 14)}</p>
                        </>
                    }
                  </span>
                )

              }
              <img src="./assets/icons/attachment-pin.svg" alt="" />
              <p>{WORD_ADD_ATTACHMENT} <span style={{ color: "red" }}>*</span></p>
              <input
                type="file"
                name=""
                id="file-attach-button"
                hidden
                onClick={(e) => (e.target.value = null, setInputErrors(initialInputObject()))}
                onChange={(e) => {
                  e.target.files.length && setInputs((s) => ({ ...s, pdf: e.target.files[0] }))
                }}
                accept="application/pdf"
              />
            </label>
            {inputErrors?.pdf && (
              <p className="error-message">{inputErrors?.pdf}</p>
            )}
          </span>
          <div className="support-files">
            <span className="attachment-button">
              <label className="attachment-button supports" htmlFor="support-file-attach-button">
                <img src="./assets/icons/attachment-pin.svg" alt="" />
                <p>{WORD_ADD_SUPPORTING_DOCS}</p>
                <input
                  type="file"
                  multiple
                  name=""
                  id="support-file-attach-button"
                  hidden
                  onClick={(e) => (e.target.value = null, setInputErrors(initialInputObject()))}
                  onChange={(e) =>
                    setInputs((s) => ({ ...s, supportingFiles: [...s.supportingFiles, ...e.target.files] }))
                  }
                // accept="application/pdf"
                />
              </label>
            </span>
            <p className="supported__formats">{WORD_FILE_UPLOAD_SUPPORTED_FORMATS}</p>
            <span className="files-preview">
              {
                inputs?.supportingFiles?.length > 0 && Array.from(inputs?.supportingFiles).map((item, i) => {
                  return (
                    <span className="file-preview" key={i}>
                      <span className="close-button" onClick={() => fileDeleteHandler(item)}>x</span>
                      <p>{item.name.toLowerCase()}</p>
                    </span>
                  )
                })
              }
            </span>
          </div>
        </>
      ) : (flag === 3 || flag === 2) ? (
        <button className="cancel" onClick={() => onBack()} disabled={loader1}>{WORD_BACK}</button>
      ) : null}
      <span className="end-buttons">
        {loader1 && <img src="assets/loader/loader.svg" alt="" />}
        <button className="danger" onClick={() => onCancel()} disabled={loader1}>
          {WORD_CANCEL}
        </button>
        {flag === 1 ? (
          <button onClick={() => onNext()} disabled={loader1}>{WORD_NEXT}</button>
        ) : flag === 2 || flag === 3 ? (
          <button onClick={() => onSave()} disabled={loader1}>{WORD_NEXT}</button>
        ) : null}
      </span>
    </div>
  );
}
