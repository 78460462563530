import React, { useEffect, useState } from "react";
import "./Header_Main_Layout.scss";
import { useDispatch, useSelector } from "react-redux";
import { User, pdf_open_status, updateRedux } from "../../redux/commonReducer";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { WORD_LEAVE, WORD_LEAVE_CONFIRMATION, WORD_LOGOUT, WORD_STAY } from "../../constants/translation";
import Modal from "../../Tools/Modal/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { getModulePermission } from "../../redux/actionCreator";
// import { List, X } from "react-bootstrap-icons";

export default function Header_Main_Layout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const pdf_status = useSelector(pdf_open_status);

  const [modalshow, setModalShow] = useState(false);
  const [Icon, setIcon] = useState(true);

  const handleClose = () => setModalShow(false);
  const closeMenu = () => {
    var element = document.body;
    element.classList.toggle("close-menu");
    setIcon(!Icon);
  };
  function logoutHandler() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("username");
    localStorage.removeItem("employee_id");
    localStorage.removeItem("user_type");
    localStorage.removeItem("permission");
    localStorage.removeItem("is_tech_support");
    dispatch(updateRedux({ key: "employee_id", value: "" }));
    navigate("/login");
  }
  const user = JSON.parse(localStorage.getItem("user"));
  // let user = useSelector(User)

  const onLeaveHandler = () => {
    handleClose();
    logoutHandler();
    dispatch(updateRedux({ key: "pdf_open_status", value: false }));
  };

  useEffect(() => {
    dispatch(getModulePermission());
  }, []);

  return (
    <div id="Header_Main_Layout">
      <div className="close__icon">
        {Icon ? (
          <>
            <i className="bi bi-list" onClick={closeMenu}></i>
          </>
        ) : (
          <i className="bi bi-x" onClick={closeMenu}></i>
        )}
      </div>
      <div className="user-name">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className="user-icon">
              <img src="./assets/icons/profile-icon.svg" alt="" />
              <p>{user?.username}</p>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                if (pdf_status) {
                  setModalShow(true);
                } else {
                  logoutHandler();
                }
              }}
            >
              {WORD_LOGOUT}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Modal show={modalshow} centered onHide={handleClose}>
        <div id="CheckBoxModal">
          <h3 dir="ltr">{WORD_LEAVE_CONFIRMATION}</h3>
          <div className="buttons">
          <button className="cancel" onClick={onLeaveHandler}>
              {WORD_LEAVE}
            </button>
            <button className="confirm" onClick={handleClose}>
              {WORD_STAY}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
