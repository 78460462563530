import React, { useContext, useEffect, useState } from "react";
import "./FormAddPerson.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSubUnits,
  getAllUnits,
  getRankData,
  getUser,
  getUserList,
} from "../../redux/actionCreator";
import { RequestInputContext } from "../../pages/ManageRequests/CreateRequest";
import { AssignSignatoryContext } from "../AssignSignatory/AssignSignatory";
import Select from "react-select";
import {
  WORD_APPROVE_WO_COMMENT,
  WORD_CLEAR,
  WORD_JOB_TITLE,
  WORD_NAME,
  WORD_RANK,
  WORD_SEARCH,
  WORD_SELECT_SUBUNIT,
  WORD_SELECT_UNIT,
  WORD_SELECT_USER,
  WORD_SUBUNIT,
  WORD_UNIT,
  WORD_VIEW_COMMENTS,
  WORD_SHOW_SIGNATURE,
  WORD_TYPE_JOB_TITLE,
  WORD_SEARCH_KEYWORD,
  WORD_SECRETARY,
  WORD_SIGNATORY,
  WORD_SELECT_RANK,
  WORD_REVIEWER,
} from "../../constants/translation";

export default function FormAddPerson({
  name,
  serialNo = 1,
  inputs = {},
  setInputs,
  inputErrors,
  requestID,
  prevData,
  previousData,
  setPrevData,
  index,
  requestDetails
}) {
  const CreateRequestContextValues = useContext(RequestInputContext);
  if (CreateRequestContextValues) {
    ({ inputs, setInputs, inputErrors } = CreateRequestContextValues);
  }
  const AssignSignatoryContextValues = useContext(AssignSignatoryContext);
  if (AssignSignatoryContextValues) {
    ({ inputs, setInputs, inputErrors } = AssignSignatoryContextValues);
  }

  const dispatch = useDispatch();
  const [dropDowns, setDropDowns] = useState({});
  const [unit, setUnit] = useState({ unit_name: WORD_SELECT_UNIT });
  const [subunit, setSubunit] = useState({ subunit_name: WORD_SELECT_SUBUNIT });
  const [jobTitle, setJobTitle] = useState("");
  const [rank, setRank] = useState({ rank: WORD_SELECT_RANK });
  const [user, setUser] = useState(previousData ? previousData : '');
  const [rankList, setRankList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isReviewer, setIsReviewer] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    dispatch(
      getAllUnits({}, (res) => setDropDowns((s) => ({ ...s, units: res })))
    );
    let formdata = new FormData();
    name == "Secretary" && formdata.append("is_secretery", "1");
    requestID && formdata.append("request_id", requestID);
    name === 'Signatory' && serialNo === 1 && formdata.append('is_reveiwer', inputs.isReviewer)
    dispatch(
      getUser(formdata, (res) => {
        setDropDowns((s) => ({ ...s, users: res }));
      })
    );


    let unitEnteredValue = inputs[name]?.find(
      (o, i) => o.sl_no === serialNo
    )?.unit;
    let subUnitEnteredValue = inputs[name]?.find(
      (o, i) => o.sl_no === serialNo
    )?.subunit;
    if (unitEnteredValue) subunitsPopulating(unitEnteredValue);
    if (subUnitEnteredValue) usersPopulating(subUnitEnteredValue);
    dispatch(getRankData((res) => {
      setRankList(res.rank_details);
    }))
  }, [inputs.isReviewer]);

  useEffect(() => {
    if (prevData) {
      if (inputs && inputs[name] && inputs[name][index] && prevData._id != inputs[name][index].name) {
        let temp = inputs?.[name].find(e => e.sl_no === serialNo)?.name;
        let uservalue = dropDowns?.users?.find(e => e._id == temp)
        if (uservalue) {
          setUser({ username: uservalue.username, _id: uservalue._id, name: uservalue.name, employee_id: uservalue.employee_id,  rank: uservalue.rank })
        }
      } else {
        setUser({ username: prevData.username, _id: prevData._id, name: prevData.name, employee_id: prevData.employee_id, rank: prevData.rank })
      }

    } else if (inputs?.[name].some(e => e.sl_no === serialNo)) {
      let temp = inputs?.[name].find(e => e.sl_no === serialNo)?.name;
      let uservalue = dropDowns?.users?.find(e => e._id == temp)
      if (uservalue) {
        setUser({ username: uservalue.username, _id: uservalue._id, name: uservalue.name, employee_id: uservalue.employee_id, rank: uservalue.rank })
      }
    }
  }, [prevData, dropDowns, inputs, name])


  useEffect(() => {
    if (previousData) {
      // console.log({ inputs }, 'previous')
      if (inputs && inputs[name] && inputs[name][index] && previousData._id != inputs[name][index].name) {
        let temp = inputs?.[name].find(e => e.sl_no === serialNo)?.name;
        let uservalue = dropDowns?.users?.find(e => e._id == temp)
        if (uservalue) {
          setUser({ username: uservalue.username, _id: uservalue._id, name: uservalue.name, employee_id: uservalue.employee_id, rank: uservalue.rank })
        }
      } else {
        console.log({ previousData })
        if (previousData?.username != undefined && previousData?.username != undefined) {
          setUser({ username: previousData.username, _id: previousData._id, name: previousData.name, employee_id: previousData.employee_id , rank: previousData.rank})
        } else {
          setUser("")
        }
      }
    } else if (inputs?.[name].some(e => e.sl_no === serialNo)) {
      let temp = inputs?.[name].find(e => e.sl_no === serialNo)?.name;
      let uservalue = dropDowns?.users?.find(e => e._id == temp)
      if (uservalue) {
        setUser({ username: uservalue.username, _id: uservalue._id, name: uservalue.name, employee_id: uservalue.employee_id, rank: uservalue.rank })
      }
    }
  }, [previousData, dropDowns, inputs, name])

  async function inputHandler(key, value) {
    setInputs((s) => ({
      ...s,
      [name]: s[name].some((obj) => obj.sl_no === serialNo)
        ? s[name].map((obj) => obj.sl_no === serialNo ? { ...obj, [key]: value } : obj)
        : [...s[name], { sl_no: serialNo, [key]: value }],
    }));
    if (key === "unit") subunitsPopulating(value);
  }


  function subunitsPopulating(value) {
    dispatch(
      getAllSubUnits(value, (res) => {
        setDropDowns((s) => ({ ...s, subUnits: res.subunit_details }));
      })
    );
    let formdata = new FormData();
    formdata.append("unit", value);
    name === "Secretary" && formdata.append("is_secretery", "1");
    requestID && formdata.append("request_id", requestID);
    // dispatch(getUser(formdata, (res) => { setDropDowns(s => ({ ...s, users: res })) }))
  }
  function usersPopulating() {
    setUser("")
    setLoader(true)
    let formdata = new FormData();
    Object.keys(unit).length > 1 && formdata.append("unit", unit._id);
    Object.keys(subunit).length > 1 && formdata.append("subunit", subunit._id);
    Object.keys(rank).length > 1 && formdata.append("rank", rank._id);
    Object.keys(jobTitle).length > 1 && formdata.append("job_title", jobTitle);
    keyword && formdata.append("keyword", keyword);
    requestID && formdata.append("request_id", requestID);
    name === "Secretary" && formdata.append("is_secretery", "1");
    name === 'Signatory' && serialNo === 1 && formdata.append('is_reveiwer', inputs.isReviewer)
    dispatch(
      getUser(formdata, (res) => {
        setDropDowns((s) => ({ ...s, users: res }));
        setLoader(false)
      })
    );
  }

  function onClearHandler() {
    setUnit({ unit_name: WORD_SELECT_UNIT });
    setSubunit({ subunit_name: WORD_SELECT_SUBUNIT });
    setRank({ rank: "Select rank" });
    setJobTitle("");
    setKeyword("");
    dispatch(
      getUser({}, (res) => {
        setDropDowns((s) => ({ ...s, users: res }));
      })
    );
  }

  useEffect(() => {
    if (user && Object.keys(user)?.length === 0) {
      setInputs && setInputs((s) => ({
        ...s,
        [name]: s[name]?.filter((item) => item.sl_no !== serialNo),
      }));
    }
  }, [user]);

  useEffect(() => {
    if (requestDetails) {
      let filteredUsers = dropDowns.users?.filter(item => {
        return !requestDetails.signatory.some(sign => {
          return sign.signatory._id === item._id && sign.status === 'pending'
        })
      })
      setDropDowns(s => ({ ...s, users: filteredUsers }))
    }
  }, [requestDetails])
  // console.log({ user,prevData })

  const colourStyles = {
    control: (base) => ({
      ...base,
      height: 48,
      // minHeight: 35
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        direction : "ltr",
        textAlign: "right",
      };
    },
    singleValue : (base) => ({
      ...base,
      direction : "ltr",
      textAlign: "right",
    }),
  };

  return (
    <div id="FormAddPerson">
      <h3 className="title">
        {name == "Secretary" ? WORD_SECRETARY : (WORD_SIGNATORY + " " + (name === "Secretary" ? "" : serialNo) + (serialNo === 1 ? '/' + WORD_REVIEWER : ''))}
      </h3>
      <div>
        {name === 'Signatory' && serialNo === 1 &&
          <span className="reviewer_or">
            <label htmlFor="isReveiwer_signatory">
              <input type="radio" id='isReveiwer_signatory' checked={!inputs?.Signatory[index]?.isReviewer} name="isReviewer" onChange={(e) => { setIsReviewer(!e.target.checked); inputHandler("isReviewer", !e.target.checked); setInputs(s => ({ ...s, "isReviewer": !e.target.checked })) }} />
              {WORD_SIGNATORY}
            </label>
            <label htmlFor="isReveiwer_reviewer">
              <input type="radio" id='isReveiwer_reviewer' checked={inputs?.Signatory[index]?.isReviewer} name="isReviewer" onChange={(e) => { setIsReviewer(e.target.checked); inputHandler("isReviewer", e.target.checked); setInputs(s => ({ ...s, "isReviewer": e.target.checked })) }} />
              {WORD_REVIEWER}
            </label>
          </span>
        }
      </div>
      <div className="inputs">
        <span>
          <label htmlFor="">{WORD_UNIT}</label>
          <Select
            name="unit"
            options={dropDowns.units}
            // isDisabled={fullUserList?.length == 0}~
            hideSelectedOptions={false}
            value={unit}
            getOptionLabel={(option) => option.unit_name}
            getOptionValue={(option) => option._id}
            className="basic-select"
            classNamePrefix="select"
            styles={{
              control: (base) => ({
                ...base,
                height: 48,
                // minHeight: 35
              }),
            }}
            onChange={(e) => (
              setUnit(e),
              setSubunit({ subunit_name: WORD_SELECT_SUBUNIT }),
              setUser(""),
              inputHandler("unit", e._id)
            )}
          />
          {/* <img
            className="dropdown-arrow"
            src="./assets/icons/arrow-down.svg"
            alt=""
          /> */}
        </span>
        <span>
          <label htmlFor="">{WORD_SUBUNIT}</label>
          <Select
            name="unit"
            options={dropDowns.subUnits}
            isDisabled={Object.keys(unit).length <= 1}
            hideSelectedOptions={false}
            value={subunit}
            getOptionLabel={(option) => option.subunit_name}
            getOptionValue={(option) => option._id}
            className="basic-select"
            classNamePrefix="select"
            styles={{
              control: (base) => ({
                ...base,
                height: 48,
                // minHeight: 35
              }),
            }}
            onChange={(e) => (
              setSubunit(e),
              setUser(""),
              inputHandler("subunit", e._id)
            )}
          />
          {/* <img
            className="dropdown-arrow"
            src="./assets/icons/arrow-down.svg"
            alt=""
          /> */}
        </span>
        <span>
          <label htmlFor="">{WORD_JOB_TITLE}</label>
          <input
            type="text"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            placeholder={WORD_TYPE_JOB_TITLE}
          />
          {/* <img className='dropdown-arrow' src="./assets/icons/arrow-down.svg" alt="" /> */}
        </span>
        {/* <span>
                    <label htmlFor="">Job Title</label>
                    <Select
                        name="unit"
                        options={dropDowns.units}
                        // isDisabled={fullUserList?.length == 0}
                        hideSelectedOptions={false}
                        value={unit}
                        getOptionLabel={(option) => option.unit_name}
                        getOptionValue={(option) => option._id}
                        className="basic-select"
                        classNamePrefix="select"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#31D68F",
                                primary: "#0D7B58",
                            },
                        })}
                        styles={{
                            control: base => ({
                                ...base,
                                height: 48,
                                // minHeight: 35
                            })
                        }}
                        onChange={(e) => (setUnit(e), setSubunit({}), setUser({}), inputHandler('unit', e._id))}
                    />
                    <img className='dropdown-arrow' src="./assets/icons/arrow-down.svg" alt="" />
                     <p className='error-message'>error message</p> 
            </span> */}
        <span>
          <label htmlFor="">{WORD_RANK}</label>
          <Select
            name="unit"
            options={rankList}
            // isDisabled={fullUserList?.length == 0}
            hideSelectedOptions={false}
            value={rank}
            getOptionLabel={(option) => option.rank}
            getOptionValue={(option) => option._id}
            className="basic-select"
            classNamePrefix="select"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#31D68F",
                primary: "#0D7B58",
              },
            })}
            styles={{
              control: (base) => ({
                ...base,
                height: 48,
                // minHeight: 35
              }),
            }}
            onChange={(e) => setRank(e)}
          />
          {/* <img
            className="dropdown-arrow"
            src="./assets/icons/arrow-down.svg"
            alt=""
          /> */}
        </span>
        <span className="search-keyword">
          <input
            type="text"
            placeholder={WORD_SEARCH_KEYWORD}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <button className="clear" onClick={() => onClearHandler()}>
            {WORD_CLEAR}
          </button>
          <button onClick={() => usersPopulating()}>
            {loader && <img className="ms-4" src="assets/loader/loader-white.svg" alt="" />}
            {WORD_SEARCH}
          </button>
        </span>
        <span className="name">
          <label htmlFor="">{WORD_NAME}</label>
          <Select
            isClearable={true}
            name="unit"
            options={name == "Signatory" ? dropDowns.users?.filter((user) => {
              return !inputs.Signatory?.some((sign) => {
                return user._id === sign.name;
              });
            }) : name == "Secretary" ? dropDowns.users?.filter((user) => {
              return !inputs.Secretary?.some((sign) => {
                return user._id === sign.name;
              });
            }) : []}
            // isDisabled={fullUserList?.length == 0}
            hideSelectedOptions={false}
            value={user}
            // getOptionLabel={(option) => {return <span style={{textAlign: "right"}} dir="ltr">{`( ${option?.rank[0]?.rank}) ${option?.name} (${option?.employee_id})`}</span>}}
            getOptionLabel={(option) => `(${option?.rank[0]?.rank}) ${option?.name} (${option?.employee_id})`}
            getOptionValue={(option) => option._id}
            className="basic-select"
            classNamePrefix="select"
            placeholder={WORD_SELECT_USER}
            // styles={{
            //   control: (base) => ({
            //     ...base,
            //     height: 48,
            //     // minHeight: 35
            //   }),
            // }}
            onChange={(e) => (setUser(e), inputHandler("name", e?._id))}
            styles={colourStyles}
          />
          {/* <img
            className="dropdown-arrow"
            src="./assets/icons/arrow-down.svg"
            alt=""
          /> */}
        </span>
      </div>
      {name === "Signatory" && (
        <span className="checkbox">
          {/* {
            name === 'Signatory' && serialNo === 1 &&
            <>
              <input
                type="checkbox"
                name=""
                id=""
                checked={
                  inputs[name]?.find((o, i) => o.sl_no === serialNo)?.view_comment
                }
                onChange={(e) => inputHandler("view_comment", e.target.checked)}
              />
              <label htmlFor="">Reviewer</label>
            </>
          } */}
          <input
            type="checkbox"
            name=""
            id={"wo_comment" + serialNo}
            checked={
              inputs[name]?.find((o, i) => o.sl_no === serialNo)?.comment_allowed
            }
            onChange={(e) => inputHandler("comment_allowed", e.target.checked)}
          />
          <label htmlFor={"wo_comment" + serialNo}>{WORD_APPROVE_WO_COMMENT}</label>
          {/* <input
            type="checkbox"
            name=""
            id=""
            checked={
              inputs[name]?.find((o, i) => o.sl_no === serialNo)?.view_comment
            }
            onChange={(e) => inputHandler("view_comment", e.target.checked)}
          />
          <label htmlFor="">{WORD_VIEW_COMMENTS}</label> */}
          {/* <input
            type="checkbox"
            name=""
            id=""
            checked={
              inputs[name]?.find((o, i) => o.sl_no === serialNo)?.show_signature
            }
            onChange={(e) => inputHandler("show_signature", e.target.checked)}
          />
          <label htmlFor="">{WORD_SHOW_SIGNATURE}</label> */}
        </span>
      )}
    </div>
  );
}
