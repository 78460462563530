import React, { useEffect, useState } from "react";
import "./PaginationRow.scss";
import {
  WORD_ENTRIES,
  WORD_EXPORT,
  WORD_SEARCH,
  WORD_SHOW,
} from "../../constants/translation";
import { useSelector } from "react-redux";
import { report_export_loader } from "../../redux/commonReducer";

export default function PaginationRow({
  page,
  onSearch,
  onCount,
  exportData,
  setSearchKey,
}) {
  let flag =
    page === "AddSignature"
      ? 1
      : page === "reports"
      ? 2
      : page === "assignrole"
      ? 3
      : page === "dashboard"
      ? 4
      : page === "closed"
      ? 5
      : page === "inprogress"
      ? 6
      : (page === "pending" || page === "tech_pending")
      ? 7
      : (page === "archived" || page === "tech_archived")
      ? 8
      : 0;

  const exportLoader = useSelector(report_export_loader);
  const [lineCount, setLineCount] = useState(10);

  // function searchHandler(value) {
  //   onSearch && onSearch(value);
  // }

  function lineCountHandler(value) {
    setLineCount((s) => {
      s = parseInt(s);
      console.log(s, lineCount);
      if (s === 1 && value === -1) return s;
      return (s += value);
    });
    console.log(value);
    // if(value == 0 || value == -1) {
    //   setLineCount(parseInt(1));
    // } else {
    //   setLineCount(parseInt(value));
    // }
  }

  useEffect(() => {
    onCount && onCount(lineCount);
  }, [lineCount]);

  function onChangeHandler(value) {
    setLineCount(parseInt(value));
  }

  function keyDownHandler(e) {
    if (e.code === "Enter") {
      onSearch();
    }
  }

  // console.log(flag===2,"Aslam")
  return (
    <div id="PaginationRow">
      <span className="Count">
        <p>{WORD_ENTRIES}</p>
        <span className="number-input">
          <input
            type="number"
            value={lineCount}
            // placeholder="0"
            min={1}
            onChange={(e) => {
              if (e.target.value == 0) {
                onChangeHandler(1);
              } else {
                onChangeHandler(e.target.value);
              }
            }}
          />
          <img
            className="up"
            onClick={() => lineCountHandler(1)}
            src="./assets/icons/arrow-down.svg"
            alt=""
          />
          <img
            className="down"
            onClick={() => lineCountHandler(-1)}
            src="./assets/icons/arrow-down.svg"
            alt=""
          />
        </span>
        <p>{WORD_SHOW}</p>
      </span>
      {flag !== 1 &&
        flag !== 2 &&
        flag !== 3 &&
        flag !== 4 &&
        flag !== 5 &&
        flag !== 6 &&
        flag !== 7 &&
        flag !== 8 && (
          <span className="Search">
            <input
              type="text"
              placeholder={WORD_SEARCH}
              onChange={(e) => setSearchKey(e.target.value)}
              onKeyDown={(e) => keyDownHandler(e)}
            />
            <img
              src="./assets/icons/search.svg"
              alt=""
              onClick={() => onSearch()}
            />
          </span>
        )}
      {flag === 2 ? (
        <button
          className="export"
          onClick={exportData}
          style={{ cursor: "pointer", backgroundColor: "transparent" }}
          disabled={exportLoader}
        >
          <h3>{WORD_EXPORT}</h3>
          {exportLoader ? (
            <img className="" src="/assets/loader/loader.svg" alt="" />
          ) : (
            <img src="./assets/icons/export.svg" alt="" />
          )}
        </button>
      ) : (
        ""
      )}
    </div>
  );
}
