import React, { useEffect, useRef, useState } from "react";
import "./Layout_pdf.scss";
import PDFviewer from "../PDFviewer/PDFviewer";
import Header_Pdf_Layout from "../Header_Pdf_Layout/Header_Pdf_Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getRequestDetail,
  setPdfHeaderDetails,
  setPdfHeaderSelectedBtn,
  submitPDF,
} from "../../redux/actionCreator";
import { API_BASE_URL } from "../../constants/configuration";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  WORD_BACK,
  WORD_SIGNATORIES_ASSIGNED_SUCCESFULLY,
  WORD_SIGNATURE_BOX_NOT_SPECIFIED,
  WORD_SUBMIT,
} from "../../constants/translation";
import { pdf_open_status, updateRedux } from "../../redux/commonReducer";

export default function Layout_pdf({ name }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [pages, setPages] = useState();
  const [boxes, setBoxes] = useState();
  const [details, setRequestDetails] = useState();
  const [loader, setLoader] = useState(false)
  const [loader1, setLoader1] = useState(true)

  const pdfDetails = useSelector((s) => s.commonReducer.pdfHeaderDetails);
  const pdf_status = useSelector(pdf_open_status);

  const { id: request_id } = useParams();


  // console.log({ location })
  useEffect(() => {
    let obj = {
      id: request_id,
      type: "0",
      is_archived: 0
    }
    getRequestDetail(obj, (res) => {
      // console.log(res, "res")
      setRequestDetails(res);
    });
  }, []);

  useEffect(() => {
    dispatch(
      setPdfHeaderDetails({
        pages: pages,
        signatories: details?.request_details?.signatory,
      })
    );
  }, [pages, details]);

  useEffect(() => {
    dispatch(updateRedux({ key: "pdf_open_status", value: true }));
  }, []);

  function submitHandler() {
    // console.log(pdfDetails.signatories, 'sinboxerror')
    let isValidated = true;
    pdfDetails.signatories
      .filter((item) => item.status === "pending")
      .filter((item) => !item.is_reviewer)
      .forEach((item) => {
        let res = boxes.some((box) => {
          return box.signatory_id._id === item.signatory._id;
        });
        if (!res) {
          isValidated = false;
        }
      });
    if (!isValidated) {
      toast.error(WORD_SIGNATURE_BOX_NOT_SPECIFIED, {
        position: "bottom-center",
        autoClose: 3000,
      });
      return;
    }
    setLoader(true)
    let formdata = new FormData();
    formdata.append("request_id", request_id);
    boxes.forEach((box, i) => {
      formdata.append(`xaxis[${i}]`, box.x);
      formdata.append(`yaxis[${i}]`, box.y);
      formdata.append(`signatory[${i}]`, box.signatory_id._id);
      formdata.append(`page[${i}]`, box.page);
    });
    submitPDF(formdata, (res) => {
      setLoader(false)
      toast.success(WORD_SIGNATORIES_ASSIGNED_SUCCESFULLY, {
        position: "bottom-center",
        autoClose: 3000,
      });
      dispatch(setPdfHeaderSelectedBtn({}, []));
      dispatch(updateRedux({ key: "pdf_open_status", value: false }));
      if (res.status) {
        if (localStorage.getItem("page_url") != undefined) {
          navigate(localStorage.getItem("page_url"))
        } else {
          navigate("/")
        }
      };
    });
  }

  // console.log('pdf details', details)
  // console.log('boxes', boxes)

  return (
    // console.log(request_id,"1236"),
    <div id="Layout_pdf">
      {/* <header className="document-header">
                <Header_Pdf_Layout pages={pages} signatories={details?.request_details?.signatory} />
            </header> */}
      <div className="document-platform">
        {/* <header>
                    <img className='logo-1' src="./assets/images/TSD logo.png" alt="" />
                    <img className='logo-2' src="./assets/icons/dubai-police green.svg" alt="" />
                </header> */}
        {/* {console.log(`${API_BASE_URL}${details?.request_path}/${name === "assign_signatory" ? "preview-" : ""
          }${details?.request_details?.pdf}`, "159")} */}
        {details && <PDFviewer
          url={`${API_BASE_URL}${details?.request_path}/${name === "assign_signatory" ? "output-" : ""
            }${details?.request_details?.pdf}`}
          setPagesCount={setPages}
          loaderFunc={setLoader1}
          onChange={(e) => setBoxes(e)}
        />}
        {
          loader1 && <img
            className="circles-loader"
            src="/assets/loader/circles.svg"
            alt=""
          />
        }
        {!loader1 &&
          <span className="buttons">
            <button
              className="cancel"
              onClick={() => {
                dispatch(updateRedux({ key: "pdf_open_status", value: false }));
                if (location?.state?.isHigherLevel) {
                  navigate('../manage_requests/assign_signatory', {
                    state: { id: request_id, back: true, level: location.state.level },
                  })
                } else {
                  navigate("/manage_requests/create_request", {
                    state: { id: request_id, back: true },
                  });
                }
              }}
              disabled={loader}
            >
              {WORD_BACK}
            </button>
            <button className="submit" onClick={submitHandler} disabled={loader}>
              {WORD_SUBMIT}
              {loader && <img className="me-4" src="assets/loader/loader-white.svg" alt="" />}
            </button>
          </span>}
      </div>
    </div>
  );
}
