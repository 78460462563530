import React, { useState } from "react";
import {
  WORD_ADD_ATTACHMENT,
  WORD_ADD_SUPPORTING_DOCS,
  WORD_CANCEL,
  WORD_DESCRIPTION,
  WORD_ENTER_DESCRIPTION,
  WORD_ENTER_REQUEST_DETAILS,
  WORD_ENTER_TITLE,
  WORD_FIELD_CANT_BLANK,
  WORD_FIELD_CANT_BLANK_WARNING,
  WORD_FILE_FORMAT_NOT_SUPPORTED,
  WORD_FILE_TOO_BIG_VALIDATION,
  WORD_FILE_UPLOAD_SUPPORTED_FORMATS,
  WORD_NOT_PDF_FILE,
  WORD_REQUEST_ADDED_SUCCESSFULLY,
  WORD_SUBMIT,
  WORD_TITLE,
} from "../../constants/translation";
import PageSubTitle from "../../components/PageSubTitle/PageSubTitle";
import { useDispatch } from "react-redux";
import { addTechSupportRequest } from "../../redux/actionCreator";
import { toast } from "react-toastify";
import { modulePermission } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

const TechCreateRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({
    title: "",
    description: "",
    publish: 0,
  });
  const [err, setErr] = useState({});
  const [documents, setDocuments] = useState([]);
  const [files, setFiles] = useState([]);
  const [disableButtons, setDisabledButtons] = useState([]);
  const [loader, setLoader] = useState(false);

  const addToDisabled = (btn) => {
    setDisabledButtons([...disableButtons, btn]);
  };

  const removeFromDisabled = (btn) => {
    setDisabledButtons(disableButtons?.filter((e) => e != btn));
  };

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const fileSelectedHandler = (e) => {
    console.log(e.target.files, "e");
    let formattedImage = [];
    let formattedFiles = [];
    let tempErr = [];
    let temp = [...e.target.files];
    let size = null;
    documents.forEach((ele) => (size = size + ele?.size));
    console.log(documents, size, "inSize");
    temp.forEach((ele) => {
      let extension = ele.name
        .substr(ele.name.lastIndexOf(".") + 1)
        .toLowerCase();
      size = size + ele.size;
      if (
        [
          "jpg",
          "jpeg",
          "png",
          "pdf",
          "PDF",
          "docx",
          "doc",
          "xlsx",
          "xls",
          "csv",
          "ppt",
          "pptx",
        ].includes(extension)
      ) {
        setErr({ ...err, files: "" });
      } else {
        tempErr.push(ele);
        setErr({
          ...err,
          files: WORD_FILE_FORMAT_NOT_SUPPORTED,
        });
      }
      const fileSize = Math.round(size / 1024);
      console.log(fileSize, "tSize");
      if (fileSize > 50 * 1024) {
        console.log("1");
        tempErr.push(ele);
        setErr({
          ...err,
          files: WORD_FILE_TOO_BIG_VALIDATION,
        });
        document.getElementById("file2").value = null;
      } else if (fileSize <= 50 * 1024 && tempErr?.length == 0) {
        console.log("2");
        setErr({
          ...err,
          files: "",
        });
        // if (["jpeg", "jpg", "png"].includes(extension)) {
        //   formattedImage.push({
        //     url: URL.createObjectURL(ele),
        //     image: ele,
        //   });
        // } else {
        formattedFiles.push({
          url: URL.createObjectURL(ele),
          ext: extension,
          file: ele,
        });
        // }
        setFiles([...files, ...formattedFiles]);
        setDocuments([...documents, ...e.target.files]);
      }
    });
  };

  const validate = () => {
    let temp = {};
    if (data.title?.trim()?.length == 0) {
      temp = { ...temp, title: WORD_FIELD_CANT_BLANK_WARNING };
    }
    if (data.description?.trim()?.length == 0) {
      temp = { ...temp, description: WORD_FIELD_CANT_BLANK_WARNING };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onCancelHandler = () => {
    setData({
      title: "",
      description: "",
    });
    setFiles([]);
    setErr({});
    document.getElementById("file1").value = null;
    setDocuments([]);
  };

  const onSubmitHandler = () => {
    if (validate()) {
      addToDisabled("submitButton");
      let formData = new FormData();
      formData.append("title", data.title?.trim());
      formData.append("description", data.description);
      documents.forEach((item, i) => formData.append(`attachment`, item));
      setLoader(true);
      dispatch(
        addTechSupportRequest(formData, (res) => {
          if (res.status) {
            toast.success(WORD_REQUEST_ADDED_SUCCESSFULLY, {
              position: "bottom-center",
              autoClose: 3000,
            });
            onCancelHandler();
            navigate("/tech_support/pending_requests");
          }
          setLoader(false);
          removeFromDisabled("submitButton");
        })
      );
    }
  };

  return (
    <div id="ManageRequests">
      <div id="RequestDetailsEntry">
        <section className="title">
          <PageSubTitle name={WORD_ENTER_REQUEST_DETAILS} />
        </section>
        <section>
          <div id="FormRequestDetails" className="form">
            <span>
              <label htmlFor="" className="d-flex">
                {WORD_TITLE}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                placeholder={
                  WORD_ENTER_TITLE ? WORD_ENTER_TITLE : "Enter Title"
                }
                className={err?.title ? "error" : ""}
                value={data.title}
                onChange={(e) => {
                  onChangeHandler("title", e.target.value);
                  setErr({
                    ...err,
                    title: "",
                  });
                }}
              />
              {err?.title && <p className="error-message">{err.title}</p>}
            </span>
            <span>
              <label htmlFor="" className="d-flex">
                {WORD_DESCRIPTION}
                <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                cols=""
                rows="8"
                placeholder={WORD_ENTER_DESCRIPTION}
                className={err?.description ? "error" : ""}
                value={data.description}
                onChange={(e) => {
                  onChangeHandler("description", e.target.value);
                  setErr({
                    ...err,
                    description: "",
                  });
                }}
              />
              {err?.description && (
                <p className="error-message">{err.description}</p>
              )}
            </span>
          </div>
        </section>
        <section>
          <div id="ButtonsFooter">
            <div className="support-files">
              <span className="attachment-button">
                <label className="attachment-button supports" htmlFor="file1">
                  <img src="./assets/icons/attachment-pin.svg" alt="" />
                  <p>{WORD_ADD_SUPPORTING_DOCS}</p>
                  <input
                    type="file"
                    multiple
                    name=""
                    id="file1"
                    hidden
                    accept=".jpeg, .jpg, .png, .docx, .doc, .pdf, .PDF, .xlsx, .xls. .csv, .ppt, .pptx"
                    onChange={(e) => fileSelectedHandler(e)}
                  />
                </label>
              </span>
              {/* <p className="supported__formats">
                {WORD_FILE_UPLOAD_SUPPORTED_FORMATS}
              </p> */}
              {/* <span className="files-preview">
                {files?.length ? (
                  <>
                    {files?.map((ele, i) => {
                      return (
                        <div className="supportedFile">
                          <a
                            href={`${ele.url}`}
                            target="_blank"
                            rel="noreferrer"
                            className="cursor-pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={ele.file?.name}
                          >
                            <img
                              alt=""
                              src={
                                ["pdf", "PDF"].includes(ele.ext)
                                  ? "./assets/images/pdf.png"
                                  : ["doc", "docx"].includes(ele.ext)
                                  ? "./assets/images/word.png"
                                  : ["xlsx", "xls", "csv"].includes(ele.ext)
                                  ? "./assets/images/excel.png"
                                  : ["ppt", "pptx"].includes(ele.ext)
                                  ? "./assets/images/ppt.png"
                                  : ["jpeg", "jpg", "png"].includes(ele.ext)
                                  ? `${ele.url}`
                                  : ""
                              }
                            />
                          </a>
                          <button
                            className="btn"
                            onClick={() => {
                              setFiles(files.filter((item) => ele != item));
                              setDocuments(
                                documents?.filter((item) => ele.file != item)
                              );
                              setErr({ ...err, files: "" });
                            }}
                          >
                            x
                          </button>
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </span> */}
              <p className="supported__formats">
                {WORD_FILE_UPLOAD_SUPPORTED_FORMATS}
              </p>
              <span className="files-preview">
                {files?.length > 0 &&
                  files?.map((ele, i) => {
                    console.log(ele);
                    return (
                      <span className="file-preview" key={i}>
                        <span
                          className="close-button"
                          onClick={() => {
                            setFiles(files.filter((item) => ele != item));
                            setDocuments(
                              documents?.filter((item) => ele.file != item)
                            );
                            setErr({ ...err, files: "" });
                          }}
                        >
                          x
                        </span>
                        <a
                          href={`${ele.url}`}
                          target="_blank"
                          rel="noreferrer"
                          className="cursor-pointer"
                        >
                          <p>{ele?.file?.name.toLowerCase()}</p>
                        </a>
                      </span>
                    );
                  })}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <button className="danger" onClick={() => onCancelHandler()}>
                {WORD_CANCEL}
              </button>
              <button
                onClick={() => onSubmitHandler()}
                disabled={disableButtons?.includes("submitButton")}
              >
                {loader && (
                  <img
                    className="ms-4"
                    src="assets/loader/loader-white.svg"
                    alt=""
                  />
                )}
                {WORD_SUBMIT}
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TechCreateRequest;
