import React, { useEffect, useState } from "react";
import Table from "../components/Table/Table";
import PaginationRow from "../components/PaginationRow/PaginationRow";
import {
  getModulePermission,
  getRequest,
  deleteRequest,
} from "../redux/actionCreator";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Tools/Modal/Modal";
import {
  dashboard_approvedCount,
  dashboard_closedCount,
  dashboard_details,
  dashboard_inprogressCount,
  dashboard_loader,
  dashboard_openCount,
  dashboard_page_status,
  dashboard_pendingCount,
  dashboard_rejectedCount,
  dashboard_returnedCount,
  dashboard_total_pages,
  self_approvedCount,
  self_closedCount,
  self_inprogressCount,
  self_openCount,
  self_pendingCount,
  self_rejectedCount,
  self_returnedCount,
  updateRedux,
} from "../redux/commonReducer";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import {
  WORD_ACTIONS,
  WORD_ALL_USERS,
  WORD_APPROVED,
  WORD_APPROVED_BY,
  WORD_CREATED_BY,
  WORD_CREATED_DATE,
  WORD_CURRENT_STATUS,
  WORD_DASHBOARD,
  WORD_INPROGRESS,
  WORD_IN_PROGRESS,
  WORD_MY_REQUESTS,
  WORD_PENDING,
  WORD_REJECTED,
  WORD_REJECTED_BY,
  WORD_REQUESTS,
  WORD_REQUEST_ID,
  WORD_RETURNED,
  WORD_RETURNED_TO,
  WORD_SENT_TO,
  WORD_TITLE,
  WORD_SELECT_RANK,
  WORD_SELECT_UNIT,
  WORD_SELECT_SUBUNIT,
  WORD_DELETE_CONFIRMATION,
  WORD_YES,
  WORD_NO,
  WORD_DELETED_SUCCESSFULLY,
  WORD_SEARCH_KEYWORD_TEXT,
  WORD_SEARCH_KEYWORD,
  WORD_E_SIGN_REQUESTS,
} from "../constants/translation";
import Pagination from "../components/CommonPagination/Pagination";
import CommonSearch from "../components/FormSearch/CommonSearch";
import { useLocation, useNavigate } from "react-router";
import { capitalize } from "../utils";
import { toast } from "react-toastify";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userType = localStorage.getItem("user_type");
  const username = localStorage.getItem("username");
  const user = JSON.parse(localStorage.getItem("user"));

  const dashboard_data = useSelector(dashboard_details);
  const pageCount = useSelector(dashboard_total_pages);
  const page_status = useSelector(dashboard_page_status);
  const openCount = useSelector(dashboard_openCount);
  const approvedCount = useSelector(dashboard_approvedCount);
  const rejectedCount = useSelector(dashboard_rejectedCount);
  const pendingCount = useSelector(dashboard_pendingCount);
  const returnedCount = useSelector(dashboard_returnedCount);
  const closedCount = useSelector(dashboard_closedCount);
  const inprogressCount = useSelector(dashboard_inprogressCount);
  const selfOpenCount = useSelector(self_openCount);
  const selfApprovedCount = useSelector(self_approvedCount);
  const selfRejectedCount = useSelector(self_rejectedCount);
  const selfPendingCount = useSelector(self_pendingCount);
  const selfReturnedCount = useSelector(self_returnedCount);
  const selfInprogressCount = useSelector(self_inprogressCount);
  const loader = useSelector(dashboard_loader);

  let Titles = [
    {
      title: WORD_REQUEST_ID,
      key: "request_id",
      width: "150px",
      sort: "requestId",
    },
    { title: WORD_TITLE, key: "title", width: "180px", sort: "title" },
    {
      title: WORD_CURRENT_STATUS,
      key: "status",
      width: "180px",
      sort: "currentStatus",
    },
    {
      title: WORD_CREATED_DATE,
      key: "createdAt",
      width: "180px",
      sort: "createdAt",
    },
    // { title: WORD_ACTIONS, key: "actions", width: "100px" },
    { title: WORD_ACTIONS, key: "handler", width: "100px" },
  ];

  let superTitles = [
    {
      title: WORD_REQUEST_ID,
      key: "request_id",
      width: "150px",
      sort: "requestId",
    },
    { title: WORD_TITLE, key: "title", width: "180px", sort: "title" },
    {
      title: WORD_CURRENT_STATUS,
      key: "status",
      width: "180px",
      sort: "currentStatus",
    },
    {
      title: WORD_CREATED_BY,
      key: "createdby",
      width: "180px",
      sort: "createdBy",
    },
    {
      title: WORD_CREATED_DATE,
      key: "createdAt",
      width: "180px",
      sort: "createdAt",
    },
    //{ title: WORD_ACTIONS, key: "actions", width: "100px" },
    { title: WORD_ACTIONS, key: "handler", width: "100px" },
  ];

  const [data, setData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [rank, setRank] = useState({ rank: WORD_SELECT_RANK });
  const [unit, setUnit] = useState({ unit_name: WORD_SELECT_UNIT });
  const [subUnit, setSubUnit] = useState({ subunit_name: WORD_SELECT_SUBUNIT });
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [dashboardStatus, setDashboardStatus] = useState("");

  function setUpStatus(details) {
    // console.log({ details });
    let string;
    if (details?.currentStatus === "pending") {
      string = `${WORD_SENT_TO} : `;
    }
    if (details?.currentStatus === "approved") {
      string = `${WORD_APPROVED_BY} : `;
    }
    if (details?.currentStatus === "returned") {
      string = `${WORD_RETURNED_TO} : `;
    }
    if (details?.currentStatus === "rejected") {
      string = `${WORD_REJECTED_BY} : `;
    }
    string +=
      details?.currentId?.name +
      "\n" +
      moment(details?.updatedAt).format("DD MMM YYYY");
    return <pre dir="ltr">{string}</pre>;
  }

  useEffect(() => {
    let formData = new FormData();
    formData.append("unit", "");
    formData.append("subunit", "");
    formData.append("rank", "");
    formData.append("page", 0);
    formData.append("perPage", limit);
    formData.append("keyword", searchKey);
    formData.append("sort_key", sortColumn);
    formData.append("sort_order", -1);
    dispatch(updateRedux({ key: "dashboard_loader", value: true }));
    dispatch(
      getRequest(formData, () => {
        dispatch(updateRedux({ key: "dashboard_loader", value: false }));
      })
    );
  }, []);

  useEffect(() => {
    localStorage.setItem("page_url", location.pathname);
  }, []);

  const [modalRequestshow, setModalRequestShow] = useState(false);
  const [delId, setDelId] = useState("");
  const handleClose = () => setModalRequestShow(false);

  const deleteRequestCurrent = (id) => {
    setDelId(id);
    setModalRequestShow(true);
  };

  const onLeaveHandler = () => {
    setModalRequestShow(false);
    dispatch(
      deleteRequest(delId, () => {
        //console.log("bimal")
        toast.success(WORD_DELETED_SUCCESSFULLY, {
          position: "bottom-center",
          autoClose: 3000,
        });

        let formData = new FormData();
        formData.append("unit", unit._id ?? "");
        formData.append("subunit", subUnit._id ?? "");
        formData.append("rank", rank?._id ?? "");
        formData.append("page", 0);
        formData.append("perPage", limit);
        formData.append("keyword", searchKey);
        formData.append("sort_key", sortColumn);
        formData.append("sort_order", sortAscending ? 1 : -1);
        dispatch(getRequest(formData));
      })
    );
  };
  useEffect(() => {
    setData([]);
    dashboard_data?.forEach((item) => {
      setData((s) => [
        ...s,
        {
          request_id: item.reqId,
          title: item.title,
          status: setUpStatus(item),
          createdAt: item.createdAt,
          _id: item._id,
          createdby: item?.created_by[0]?.name,
          currentStatus: item?.currentStatus,
          handler: (
            <div className="d-flex align-items-center justify-content-right">
              <img
                className="eye"
                src="./assets/icons/eye.svg"
                alt="view"
                onClick={() => navigate(`request_details/${item?._id}`)}
              />
              {(item.currentStatus !== "approved" ||
                userType === "super_admin") && (
                <img
                  className="delete"
                  src="./assets/icons/delete1.svg"
                  alt="delete"
                  onClick={() => deleteRequestCurrent(item?._id)}
                />
              )}
            </div>
          ),
        },
      ]);
    });
  }, [dashboard_data]);

  useEffect(() => {
    dispatch(getModulePermission());
  }, []);

  const onLimitChangeHandler = (limitValue) => {
    if (limit != limitValue) {
      setLimit(limitValue);
    }
    setPage(0);
    let formData = new FormData();
    formData.append("unit", unit._id ?? "");
    formData.append("subunit", subUnit._id ?? "");
    formData.append("rank", rank?._id ?? "");
    formData.append("page", 0);
    formData.append("perPage", limitValue);
    formData.append("keyword", searchKey);
    formData.append("currentStatus", dashboardStatus?.value ?? "");
    formData.append("sort_key", sortColumn);
    formData.append("sort_order", sortAscending ? 1 : -1);
    dispatch(getRequest(formData));
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("unit", unit._id ?? "");
      formData.append("subunit", subUnit._id ?? "");
      formData.append("rank", rank?._id ?? "");
      formData.append("page", e.selected);
      formData.append("perPage", limit);
      formData.append("keyword", searchKey);
      formData.append("currentStatus", dashboardStatus?.value ?? "");
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      dispatch(getRequest(formData));
    }
  };

  const onSortHandler = (type) => {
    setSortAscending(!sortAscending);
    setSortColumn(type);
    let formData = new FormData();
    formData.append("unit", unit._id ?? "");
    formData.append("subunit", subUnit._id ?? "");
    formData.append("rank", rank?._id ?? "");
    formData.append("page", page);
    formData.append("perPage", limit);
    formData.append("keyword", searchKey);
    formData.append("currentStatus", dashboardStatus?.value ?? "");
    formData.append("sort_key", type);
    formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getRequest(formData));
  };
  return (
    <div id="Dashboard">
      {userType == "super_admin" ? (
        <>
          <div className="status-grid mb-4">
            <h1 className="page-title mr-0">{WORD_DASHBOARD}</h1>
            <h4 className="mt-2 title2">{WORD_ALL_USERS}</h4>
            <div className="row justify-content-start">
              <div
                className="col-md-2 col-tab-3"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  localStorage.removeItem("self");
                  navigate("manage_requests/inprogress_requests");
                }}
              >
                <div className="list closed">
                  <h2>{WORD_INPROGRESS}</h2>
                  <h4 style={{ color: "#000", cursor: "pointer" }}>
                    {inprogressCount}
                  </h4>
                </div>
              </div>
              <div
                className="col-md-2 col-tab-3"
                onClick={() => {
                  let obj = { value: "approved", label: "Approved" };
                  localStorage.setItem("dashboardSearch", JSON.stringify(obj));
                  localStorage.removeItem("self");
                  navigate("manage_requests/closed_requests");
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="list">
                  <h2>{WORD_APPROVED}</h2>
                  <h4>{approvedCount}</h4>
                </div>
              </div>

              <div
                className="col-md-2 col-tab-3"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  let obj = { value: "rejected", label: "Rejected" };
                  localStorage.setItem("dashboardSearch", JSON.stringify(obj));
                  localStorage.removeItem("self");
                  navigate("manage_requests/closed_requests");
                }}
              >
                <div className="list pending">
                  <h2>{WORD_REJECTED}</h2>
                  <h4 style={{ color: "#e95030", cursor: "pointer" }}>
                    {rejectedCount}
                  </h4>
                </div>
              </div>
              <div
                className="col-md-2 col-tab-3"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  let obj = { value: "returned", label: "Returned" };
                  localStorage.setItem("dashboardSearch", JSON.stringify(obj));
                  localStorage.removeItem("self");
                  navigate("manage_requests/pending_requests");
                }}
              >
                <div className="list pending">
                  <h2>{WORD_RETURNED}</h2>
                  <h4 style={{ color: "#60718a", cursor: "pointer" }}>
                    {returnedCount}
                  </h4>
                </div>
              </div>
              <div
                className="col-md-2 col-tab-3"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  let obj = { value: "pending", label: "Pending" };
                  localStorage.setItem("dashboardSearch", JSON.stringify(obj));
                  localStorage.removeItem("self");
                  navigate("manage_requests/pending_requests");
                }}
              >
                <div
                  className="list pending"
                  style={{ backgroundColor: "#F7E8C1" }}
                >
                  <h2>{WORD_PENDING}</h2>
                  <h4 style={{ color: "#8b6d14", cursor: "pointer" }}>
                    {pendingCount}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </>
      ) : null}
      <div className="status-grid mb-4 mt-4">
        <div className="row justify-content-start">
          <div className="col-md-12">
            <h4 className="mt-2 title2">
              {userType == "super_admin"
                ? `${WORD_MY_REQUESTS} - ${user.name}`
                : ""}
            </h4>
          </div>
          <div
            className="col-md-2 col-tab-3"
            onClick={() => {
              if (userType == "super_admin") {
                localStorage.setItem("self", "1");
              } else {
                localStorage.removeItem("self");
              }
              navigate("manage_requests/inprogress_requests");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="list closed">
              <h2>{WORD_INPROGRESS}</h2>
              <h4 style={{ color: "#000", cursor: "pointer" }}>
                {selfInprogressCount}
              </h4>
            </div>
          </div>
          <div
            className="col-md-2 col-tab-3"
            onClick={() => {
              let obj = { value: "approved", label: "Approved" };
              localStorage.setItem("dashboardSearch", JSON.stringify(obj));
              if (userType == "super_admin") {
                localStorage.setItem("self", "1");
              } else {
                localStorage.removeItem("self");
              }
              navigate("manage_requests/closed_requests");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="list">
              <h2>{WORD_APPROVED}</h2>
              <h4>{selfApprovedCount}</h4>
            </div>
          </div>

          <div
            className="col-md-2 col-tab-3"
            onClick={() => {
              let obj = { value: "rejected", label: "Rejected" };
              localStorage.setItem("dashboardSearch", JSON.stringify(obj));
              if (userType == "super_admin") {
                localStorage.setItem("self", "1");
              } else {
                localStorage.removeItem("self");
              }
              navigate("manage_requests/closed_requests");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="list pending">
              <h2>{WORD_REJECTED}</h2>
              <h4 style={{ color: "#e95030", cursor: "pointer" }}>
                {selfRejectedCount}
              </h4>
            </div>
          </div>
          <div
            className="col-md-2 col-tab-3"
            onClick={() => {
              let obj = { value: "returned", label: "Returned" };
              localStorage.setItem("dashboardSearch", JSON.stringify(obj));
              if (userType == "super_admin") {
                localStorage.setItem("self", "1");
              } else {
                localStorage.removeItem("self");
              }
              navigate("manage_requests/pending_requests");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="list pending">
              <h2>{WORD_RETURNED}</h2>
              <h4 style={{ color: "#60718a", cursor: "pointer" }}>
                {selfReturnedCount}
              </h4>
            </div>
          </div>
          <div
            className="col-md-2 col-tab-3"
            onClick={() => {
              let obj = { value: "pending", label: "Pending" };
              localStorage.setItem("dashboardSearch", JSON.stringify(obj));
              if (userType == "super_admin") {
                localStorage.setItem("self", "1");
              } else {
                localStorage.removeItem("self");
              }
              navigate("manage_requests/pending_requests");
            }}
            style={{ cursor: "pointer" }}
          >
            <div
              className="list pending"
              style={{ backgroundColor: "#F7E8C1" }}
            >
              <h2>{WORD_PENDING}</h2>
              <h4 style={{ color: "#8b6d14", cursor: "pointer" }}>
                {selfPendingCount}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-items">
        <section>
          <CommonSearch
            pageName = "dashboard"
            page={page}
            setPage={setPage}
            limit={limit}
            getData={getRequest}
            rank={rank}
            setRank={setRank}
            unit={unit}
            setUnit={setUnit}
            subUnit={subUnit}
            setSubUnit={setSubUnit}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            setSortAscending={setSortAscending}
            setSortColumn={setSortColumn}
            dashboardStatus={dashboardStatus}
            setDashboardStatus={setDashboardStatus}
            searchKeyPlaceholder={userType !== "super_admin" ? WORD_SEARCH_KEYWORD_TEXT : WORD_SEARCH_KEYWORD}
          />
        </section>
        <section>
          <h2 className="requests">{WORD_E_SIGN_REQUESTS}</h2>
          <PaginationRow
            page={"dashboard"}
            onCount={(count) => onLimitChangeHandler(count)}
          />
        </section>
        <section>
          {loader ? (
            <div className="loader-div">
              <img
                className="circles-loader"
                src="/assets/loader/circles.svg"
                alt=""
              />
            </div>
          ) : (
            <>
              <Table
                page={"dashboard"}
                titles={userType == "super_admin" ? superTitles : Titles}
                data={data}
                onSortHandler={onSortHandler}
                sortColumn={sortColumn}
                sortAscending={sortAscending}
              />
              <div className="align-between">
                <p style={{ direction: "ltr", color: "#0d7b58" }}>
                  {page_status}
                </p>
                <Pagination
                  page={page}
                  pageCount={pageCount}
                  handlePageChange={handlePageChange}
                />
              </div>
            </>
          )}
        </section>
        <Modal show={modalRequestshow} centered onHide={handleClose}>
          <div id="CheckBoxModal">
            <h3 dir="ltr">{WORD_DELETE_CONFIRMATION}</h3>
            <div className="buttons">
              <button className="confirm" onClick={onLeaveHandler}>
                {WORD_YES}
              </button>
              <button className="cancel" onClick={handleClose}>
                {WORD_NO}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
