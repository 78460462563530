import React, { Fragment, useEffect, useState } from "react";
import "./RequestDetails.scss";
import {
  approveRequest,
  getRequestDetail,
  rejectRequest,
  returnRequest,
  getUserData,
  secretaryRejectRequest,
  secretaryReturnRequest,
  reassignSignatory,
  reassignSecretery,
} from "../../redux/actionCreator";
import {
  Link,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { capitalize } from "../../utils";
import { API_BASE_URL } from "../../constants/configuration";
import { Buffer } from "buffer";
import {
  WORD_APPROVE,
  WORD_APPROVED,
  WORD_APPROVED_REQUEST,
  WORD_ASSIGN_SIGNATORY,
  WORD_BACK,
  WORD_CANCEL,
  WORD_COMMENTS,
  WORD_COMMENT_HERE,
  WORD_COMMENT_REQUIRED,
  WORD_CREATED_DATE,
  WORD_DESCRIPTION,
  WORD_DOCUMENT,
  WORD_NAME,
  WORD_NOTES,
  WORD_PENDING,
  WORD_PENDING_REQUEST_DETAIL,
  WORD_REJECT,
  WORD_REJECTED,
  WORD_REJECTED_REQUEST,
  WORD_REPORT_APPROVED,
  WORD_REQUESTER,
  WORD_REQUEST_APPROVED,
  WORD_REQUEST_FILE,
  WORD_REQUEST_ID,
  WORD_REQUEST_REJECTED,
  WORD_REQUEST_RETURNED,
  WORD_RETURN,
  WORD_RETURNED,
  WORD_RETURNED_REQUEST,
  WORD_SECRETARY_NOTE,
  WORD_STATUS,
  WORD_SUBMIT,
  WORD_SUBUNIT,
  WORD_SUPPORTING_DOCUMENTS,
  WORD_TIME,
  WORD_TITLE,
  WORD_UNIT,
} from "../../constants/translation";
import Modal from "../../Tools/Modal/Modal";
import FormAddPerson from "../FormAddPerson/FormAddPerson";
import axiosInstance from "../../utils/axiosInterceptor";

export default function RequestDetails({ page }) {
  const location = useLocation();
  const urlParams = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [input, setInput] = useState();
  const [inputError, setInputError] = useState();
  const [currentUserData, setCurrentUserData] = useState();
  const [assignModal, setAssignModal] = useState(false);
  const [reassignInput, setReassignInput] = useState({
    Signatory: [],
    Secretary: [],
  });
  const [userToReassign, setUserToReassign] = useState();
  const [loader, setLoader] = useState(false)
  const [loader1, setLoader1] = useState(false)
  const [loader2, setLoader2] = useState(false)
  const [loader3, setLoader3] = useState(false)
  const [loader4, setLoader4] = useState(false)

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    let obj = {
      id: urlParams.id,
      type: "0",
      is_archived: page === 'archived' ? 1 : 0
    }
    getRequestDetail(obj, (res) => {
      setData(res.request_details);
    });
  }, [assignModal]);

  useEffect(() => {
    data &&
      data?.[data?.type]?.forEach((item) => {
        if (item.priority && item[data?.type]._id === user._id) {
          setCurrentUserData(item);
        }
      });
  }, [data]);

  let flag =
    data?.type === "signatory" ? 1 : data?.type === "secretery" ? 2 : 0;

  function rejectHandler() {
    // if (!currentUserData?.comment_allowed && !input) {
    if (!input) {
      setInputError(WORD_COMMENT_REQUIRED);
      return;
    }
    setLoader1(true)
    const obj = { id: data?._id, comment: input, level: data?.currentLevel };
    rejectRequest(obj, (res) => {
      if (res.status) {
        toast.success(WORD_REQUEST_REJECTED, {
          position: "bottom-center",
          autoClose: 3000,
        });
        navigate("../");
      }
      if (res.is_deleted) navigate("../");
      setLoader1(false)
    });
  }
  function returnHandler() {
    // if (!currentUserData?.comment_allowed && !input) {
    if (!input) {
      setInputError(WORD_COMMENT_REQUIRED);
      return;
    }
    setLoader2(true)
    const obj = { id: data?._id, comment: input, level: data?.currentLevel };
    returnRequest(obj, (res) => {
      if (res.status) {
        toast.success(WORD_REQUEST_RETURNED, {
          position: "bottom-center",
          autoClose: 3000,
        });
        navigate("../");
      }
      if (res.is_deleted) navigate("../");
      setLoader2(false)
    });
  }

  function approveHandler() {
    if (!currentUserData?.comment_allowed && !input) {
      setInputError(WORD_COMMENT_REQUIRED);
      return;
    }
    setLoader3(true)
    const obj = { id: data?._id, comment: input };
    approveRequest(obj, (res) => {
      console.log(res)
      if (res.status) {
        toast.success(WORD_REQUEST_APPROVED, {
          position: "bottom-center",
          autoClose: 3000,
        });
        navigate("../");
        // console.log(res.nextSignatory)
      }
      if (res.is_deleted) navigate("../");
      setLoader3(false)
    });
  }

  function secretaryRejectHandler() {
    if (!input) {
      setInputError(WORD_COMMENT_REQUIRED);
      return;
    }
    setLoader1(true)
    const obj = { id: data?._id, comment: input, level: data?.currentLevel };
    secretaryRejectRequest(obj, (res) => {
      if (res.status) {
        toast.success(WORD_REQUEST_REJECTED, {
          position: "bottom-center",
          autoClose: 3000,
        });
        navigate("../");
      }
      if (res.is_deleted) navigate("../");
      setLoader1(false)
    });
  }
  function secretaryReturnHandler() {
    if (!input) {
      setInputError(WORD_COMMENT_REQUIRED);
      return;
    }
    setLoader2(true)
    const obj = { id: data?._id, comment: input, level: data?.currentLevel };
    secretaryReturnRequest(obj, (res) => {
      if (res.status) {
        toast.success(WORD_REQUEST_RETURNED, {
          position: "bottom-center",
          autoClose: 3000,
        });
        navigate("../");
      }
      if (res.is_deleted) navigate("../");
      setLoader2(false)
    });
  }

  // console.log({ reassignInput })
  // console.log('secretery', urlParams.id, userToReassign, reassignInput)

  function reassignHandler() {
    if (
      reassignInput[
        userToReassign?.type === "secretery"
          ? "Secretary"
          : userToReassign?.type === "signatory"
            ? "Signatory"
            : ""
      ].length === 0
    ) {
      toast.error("User not selected", {
        position: "bottom-center",
        autoClose: 3000,
      });
      return;
    }
    if (userToReassign.type === "secretery") {
      const formdata = new FormData();
      formdata.append("id", urlParams.id);
      formdata.append("secretery", userToReassign.id);
      formdata.append("new_secretery", reassignInput.Secretary[0].name);
      formdata.append("level", userToReassign.level);
      reassignSecretery(formdata, (res) => {
        if (res.status) {
          setAssignModal(false);
          setReassignInput({ Signatory: [], Secretary: [] });
          setUserToReassign();
          toast.success(res.message, {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      });
    } else if (userToReassign.type === "signatory") {
      console.log("signatory", urlParams.id, userToReassign, reassignInput);
      const formdata = new FormData();
      formdata.append("id", urlParams.id);
      formdata.append("signatory", userToReassign.id);
      formdata.append("new_signatory", reassignInput.Signatory[0].name);
      formdata.append(
        "comment_allowed",
        reassignInput.Signatory[0].comment_allowed ? 1 : 0
      );
      formdata.append("level", userToReassign.level);
      formdata.append(
        "view_comment",
        reassignInput.Signatory[0].view_comment || false
      );
      reassignSignatory(formdata, (res) => {
        if (res.status) {
          setAssignModal(false);
          setReassignInput({ Signatory: [], Secretary: [] });
          setUserToReassign();
          toast.success(res.message, {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      });
    }
  }

  function getPdf() {
    setLoader4(true)
    axiosInstance
      .get(`${API_BASE_URL}admin/getRequestPDF/${urlParams.id}`, {
        responseType: "arraybuffer",
        responseEncoding: "binary",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
      .then((res) => {
        // const result = 'data:application/pdf;base64,' + Buffer.from(res.data).toString('base64')
        // var pdf_newTab = window.open("");
        // pdf_newTab.document.write(
        //   `<html style="overflow:hidden;"><head><title>Document</title></head><body style="margin:0;"><iframe title='MY title'  width='100%' height='100%' src="${result}"></iframe></body></html>`
        // );
        const file = new Blob([res.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        // window.open(fileURL);
        // const link = document.createElement("a");
        // link.href = fileURL;
        // link.target = "_blank";
        // link.click();

        // const pdfWindow = window.open(fileURL);
        // const pdfWindow = window.location.assign(fileURL);
        // const title = data?.pdf ?? "Document";
        // const URI = data?.pdf ?? "Document";
        // const html = `
        //   <html>
        //     <head>
        //       <title>${title}</title>
        //     </head>
        //     <body style="margin:0">
        //       <embed width="100%" height="100%" src=${fileURL} type="application/pdf">
        //     </body>
        //   </html>
        // `;
        // pdfWindow.document.write(html);
        // pdfWindow.document.close();
        // pdfWindow.history.pushState(null, null, URI);
        const newWindow = window.open();
        newWindow.opener = null;
        newWindow.location.href = fileURL;
        setLoader4(false)
      })
      .catch((err) => {
        setLoader4(false)
        console.log(err, "catch");
      });
  }

  function getSupportDoc(file) {
    // console.log(file, 'pdf file')
    let extension = file.substring(file.lastIndexOf(".") + 1, file.length);
    let type = "";
    if (extension == "pdf") {
      type = "application/pdf";
    }
    if (extension == "docx") {
      type =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }
    if (extension == "xlsx") {
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    if (extension == "jpeg") {
      type = "image/jpeg";
    }
    if (extension == "png") {
      type = "image/png";
    }
    if (extension == "jpg") {
      type = "image/jpg";
    }
    if (extension == "csv") {
      type = "text/csv";
    }
    if (extension == "ppt") {
      type = "application/vnd.ms-powerpoint";
    }
    if (extension == "pptx") {
      type =
        "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    }
    // console.log(extension, type, 'extension')
    axiosInstance
      .post(
        `${API_BASE_URL}admin/getRequestDocument`,
        {
          headers: { "Content-Type": "application/pdf" },
          id: urlParams.id,
          document: file,
          type: type,
        },
        {
          responseType: "arraybuffer",
          responseEncoding: "binary",
        }
      )
      .then((res) => {
        // console.log(res, 'pdf document support')
        if (
          extension == "docx" ||
          extension == "xlsx" ||
          extension == "csv" ||
          extension == "ppt" ||
          extension == "pptx"
        ) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${file}`);
          document.body.appendChild(link);
          link.click();
        } else {
          const filecontent = new Blob([res.data], { type: type });
          const fileURL = URL.createObjectURL(filecontent);
          // const pdfWindow = window.open(fileURL);
          // const pdfWindow = window.location.assign(fileURL);
          // const title = file ?? "Document";
          // const URI = file ?? "Document";
          // const html = `
          //   <html>
          //     <head>
          //       <title>${title}</title>
          //     </head>
          //     <body style="margin:0">
          //       <embed width="100%" height="100%" src=${fileURL} type="application/pdf">
          //     </body>
          //   </html>
          // `;
          // pdfWindow.document.write(html);
          // pdfWindow.document.close();
          // pdfWindow.history.pushState(null, null, URI);
          const newWindow = window.open();
          newWindow.opener = null;
          newWindow.location.href = fileURL;
        }
        type = "";
      })
      .catch((err) => {
        console.log(err, "catch");
        type = "";
      });
  }

  return (
    // console.log(location.pathname),
    <div id="RequestDetails">
      <section className="status-details">
        <span>
          <p>{WORD_STATUS}</p>
          <p className={"status " + data?.currentStatus}>
            {/* {capitalize(data?.currentStatus || "")} */}
            {data?.currentStatus == "pending"
              ? WORD_PENDING_REQUEST_DETAIL
              : data?.currentStatus == "returned"
                ? WORD_RETURNED_REQUEST
                : data?.currentStatus == "rejected"
                  ? WORD_REJECTED_REQUEST
                  : data?.currentStatus == "approved"
                    ? WORD_APPROVED_REQUEST
                    : ""}
          </p>
        </span>
        <span>
          <p>{WORD_REQUEST_ID}</p>
          <p>{data?.reqId}</p>
        </span>
        <span>
          <p>{WORD_REQUESTER}</p>
          <p>{data?.created_by.name}</p>
        </span>
        <span>
          <p>{WORD_CREATED_DATE}</p>
          <p dir="ltr">{moment(data?.createdAt).format("DD MMM yyyy")}</p>
        </span>
      </section>
      <hr />
      <section className="description">
        <span>
          <p>{WORD_TITLE}</p>
          <p>{data?.title}</p>
        </span>
        <span>
          <p>{WORD_DESCRIPTION}</p>
          <p>{data?.description}</p>
        </span>
      </section>
      <hr />
      <section className="file">
        <p>{WORD_REQUEST_FILE}</p>
        <p onClick={() => getPdf()}>
          {WORD_DOCUMENT}{" "}
          {/* <Link target="_blank" to={`${API_BASE_URL}request/pdf/${data?.created_by._id === user._id || user.user_type === "super_admin" ? "output-" : "" }${data?.pdf}`}  ></Link> */}
          <img src="./assets/icons/eye.svg" alt="" />
          {loader4 && <img className="ms-4" src="assets/loader/loader.svg" alt="" />}
        </p>
      </section>
      {/* <hr /> */}
      {data?.supporting_document?.length > 0 && (
        <section className="support-files">
          <p className="key">{WORD_SUPPORTING_DOCUMENTS}</p>
          <div className="files">
            {data?.supporting_document?.map((item, i) => {
              return (
                <p
                  className="value"
                  onClick={() => getSupportDoc(item)}
                  key={i}
                >
                  {item} <img src="./assets/icons/eye.svg" alt="" />
                </p>
              );
            })}
          </div>
        </section>
      )}
      <hr />
      <section className="signatory-table table-responsive-sm">
        <table>
          <thead>
            <tr>
              <th style={{ width: "150px" }}>{WORD_NAME}</th>
              <th style={{ width: "140px" }}>{WORD_UNIT}</th>
              <th style={{ width: "150px" }}>{WORD_SUBUNIT}</th>
              <th style={{ width: "200px" }}>{WORD_STATUS}</th>
              <th style={{ width: "120px" }}>{WORD_TIME}</th>
              <th>{WORD_COMMENTS}</th>
              {/* {(flag === 2 || user._id === data?.created_by._id) && <th style={{ width: "120px" }}>Action</th>} */}
            </tr>
          </thead>
          <tbody>
            {data?.actions.map((action, ai) => {
              return (
                <Fragment key={ai}>
                  <tr>
                    <td colSpan="100%">
                      <hr />
                    </td>
                  </tr>
                  {action.map((elem, i) => {
                    // console.log('ELEM', elem)
                    if (elem.type === "signatory") {
                      return (
                        <tr key={i}>
                          <td>{elem.signatory.name + (elem.is_reviewer ? ' (Reviewer)' : '')}</td>
                          <td>{elem.signatory.unit[0].unit_name}</td>
                          <td>{elem.signatory.subunit[0].subunit_name}</td>
                          <td>
                            <span className={"status " + elem.status}>
                              {/* {capitalize(elem.status)} */}
                              {elem.status == "pending"
                                ? WORD_PENDING_REQUEST_DETAIL
                                : elem.status == "returned"
                                  ? WORD_RETURNED_REQUEST
                                  : elem.status == "rejected"
                                    ? WORD_REJECTED_REQUEST
                                    : elem.status == "approved"
                                      ? WORD_APPROVED_REQUEST
                                      : ""}
                            </span>
                          </td>
                          <td dir="ltr">
                            {elem.status === "pending"
                              ? ""
                              : moment(elem.date)
                                .format("DD MMM yyyy-hh:mm A")
                                .split("-")
                                .map((o, pi) => <p key={pi}>{o}</p>)}
                          </td>
                          <td>
                            {/* {data?.signatory.map((item) => {
                              if (item.signatory._id === user._id) {
                                user.view_comment = item.view_comment;
                              }
                            })} */}
                            {/* {(
                              user.view_comment ||
                              !data?.is_signatoy_logged_in ||
                              data?.is_created_logged_in ||
                              elem.signatory._id === user._id) && 
                              (
                              )} */}
                            <p>{elem?.comment} </p>
                          </td>
                          {/* {
                              (flag === 2 || user._id === data?.created_by._id) &&
                              <td>
                                {
                                  elem.status === 'pending' &&
                                  <button onClick={() => (setUserToReassign({ id: elem.signatory._id, level: elem.level, type: elem.type }), setAssignModal(true))}> Re-assign</button>
                                }
                              </td>
                            } */}
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={i}>
                          <td>{elem.secretery.name} (Secretary)</td>
                          <td>{elem.secretery.unit[0].unit_name}</td>
                          <td>{elem.secretery.subunit[0].subunit_name}</td>
                          {/* {console.log(elem.created_by)} */}
                          <td>
                            {elem.status === "approved" ||
                              elem.status === "pending" ? null : (
                              <span className={"status " + elem.status}>
                                {/* {capitalize(elem.status)} */}
                                {elem.status == "returned"
                                  ? WORD_RETURNED_REQUEST
                                  : elem.status == "rejected"
                                    ? WORD_REJECTED_REQUEST
                                    : ""}
                              </span>
                            )}
                          </td>
                          <td dir="ltr">
                            {elem.status === "approved" ||
                              elem.status === "pending"
                              ? ""
                              : moment(elem.date)
                                .format("DD MMM yyyy-hh:mm A")
                                .split("-")
                                .map((o) => <p>{o}</p>)}
                          </td>
                          <td>
                            <p>{elem?.comment}</p>
                          </td>
                          {/* {console.log(elem.created_by === elem.secretery._id)}
                            {console.log({ flag, user, elem })} */}
                          {/* {
                              (flag === 2 || user._id === data?.created_by._id) && (elem.secretery._id !== user._id || elem.created_by === elem.secretery._id) &&
                              <td>
                                {
                                  elem.status === 'pending' &&
                                  <button onClick={() => (setUserToReassign({ id: elem.secretery._id, level: elem.level, type: elem.type }), setAssignModal(true))}> Re-assign</button>
                                }
                              </td>
                            } */}
                        </tr>
                      );
                    }
                  })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </section>
      {/* {console.log(page)} */}
      {/* {console.log(data)} */}
      {/* {console.log(user._id)} */}
      {/* {console.log(page)} */}
      {/* {console.log(data?.currentStatus)} */}
      {/* {console.log(data?.currentId)} */}
      {/* {console.log(user?.user_type)} */}

      {data?.status !== 1 &&
        (page === "pending"
          ? user?.user_type === "super_admin"
            ? user._id === data?.currentId
            : true
          : true) &&
        (page === ""
          ? user?.user_type === "super_admin"
            ? user._id === data?.currentId
            : user._id === data?.currentId
              ? true
              : false
          : true) &&
        // (page === "" ? ((user?.user_type === "super_admin" && user._id === data?.currentId) || (data?.currentStatus === "returned" && user?.user_type !== "super_admin") || (data?.currentStatus === "pending" && user?.user_type !== "super_admin")) : true) &&
        page !== "in-Progress" &&
        page !== "Report-" &&
        page !== "archived" && (
          // page !== "in-Progress" &&
          // // page!== '' &&
          // (page === "" ? data?.currentStatus === "returned" : true) &&
          // page !== "Report-" && (
          <section className="notes">
            {flag === 1 ? (
              <p>{WORD_COMMENTS}</p>
            ) : flag === 2 ? (
              <p>{WORD_NOTES}</p>
            ) : null}{" "}
            {/* or notes */}
            <textarea
              maxLength="160"
              onChange={(e) => (setInputError(), setInput(e.target.value))}
              className={inputError ? "error" : ""}
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder={WORD_COMMENT_HERE}
            ></textarea>
            {inputError && <p className="error-message">{inputError}</p>}
          </section>
        )}
      {page !== "in-Progress" &&
        page !== "archived" && (
          <section className="buttons">
            {flag === 1 && (
              <>
                <button className="Reject" onClick={() => rejectHandler()} disabled={loader1 || loader2 || loader3}>
                  {loader1 && <img className="ms-4" src="assets/loader/loader-white.svg" alt="" />}
                  {WORD_REJECT}
                </button>
                <button className="Return" onClick={() => returnHandler()} disabled={loader1 || loader2 || loader3}>
                  {loader2 && <img className="ms-4" src="assets/loader/loader-white.svg" alt="" />}
                  {WORD_RETURN}
                </button>
                <button className="Approve" onClick={() => approveHandler()} disabled={loader1 || loader2 || loader3}>
                  {loader3 && <img className="ms-4" src="assets/loader/loader-white.svg" alt="" />}
                  {WORD_APPROVE}
                </button>
              </>
            )}
            {flag === 2 && (
              <>
                {!data?.is_created_logged_in && (
                  <>
                    <button
                      className="Reject"
                      onClick={() => secretaryRejectHandler()}
                      disabled={loader || loader1 || loader2}
                    >
                      {loader1 && <img className="ms-4" src="assets/loader/loader-white.svg" alt="" />}
                      {WORD_REJECT}
                    </button>
                    <button
                      className="Return"
                      onClick={() => secretaryReturnHandler()}
                      disabled={loader || loader1 || loader2}
                    >
                      {loader2 && <img className="ms-4" src="assets/loader/loader-white.svg" alt="" />}
                      {WORD_RETURN}
                    </button>
                  </>
                )}
                <button
                  className="Approve"
                  onClick={() => {
                    setLoader(true)
                    localStorage.setItem("assign_url", location.pathname);
                    navigate(
                      page === ""
                        ? "/manage_requests/assign_signatory"
                        : "../../assign_signatory",
                      {
                        state: {
                          level: data.currentLevel + 1,
                          comment: input,
                          id: data._id,
                          // isHigherLevel: true
                        },
                      }
                    );
                    setLoader(false)
                  }}
                  disabled={loader || loader1 || loader2}
                >
                  {loader && <img className="ms-4" src="assets/loader/loader-white.svg" alt="" />}
                  {WORD_ASSIGN_SIGNATORY}
                </button>
              </>
            )}
          </section>
        )}
      <Modal show={assignModal}>
        {/* {console.log(userToReassign?.type, userToReassign?.type === 'secretery' ? 'Secretary' : userToReassign?.type === 'signatory' ? 'Signatory' : '')} */}
        <FormAddPerson
          name={
            userToReassign?.type === "secretery"
              ? "Secretary"
              : userToReassign?.type === "signatory"
                ? "Signatory"
                : ""
          }
          serialNo={""}
          inputs={reassignInput}
          setInputs={setReassignInput}
          requestDetails={data}
          requestID={urlParams.id}
        />
        <div className="buttons">
          <button
            className="cancel"
            onClick={() => (
              setAssignModal(false),
              setReassignInput({ Signatory: [], Secretary: [] }),
              setUserToReassign()
            )}
            disabled={loader}
          >
            {loader && <img className="ms-4" src="assets/loader/loader-white.svg" alt="" />}
            {WORD_CANCEL}
          </button>
          <button className="submit" onClick={() => reassignHandler()} disabled={loader}>
            {loader && <img className="ms-4" src="assets/loader/loader-white.svg" alt="" />}
            {WORD_SUBMIT}
          </button>
        </div>
      </Modal>
    </div>
  );
}
