import React, { useEffect, useState } from "react";
import {
  rank_details,
  subunit_details,
  unit_details,
  updateRedux,
} from "../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSubUnits,
  getAllUnits,
  getRankData,
} from "../../redux/actionCreator";
import Select from "react-select";
import {
  WORD_APPROVED,
  WORD_AVAILABLE,
  WORD_CLEAR,
  WORD_CLOSED,
  WORD_CLOSEDـSTATUS,
  WORD_NOT_AVAILABLE,
  WORD_PENDING,
  WORD_RANK,
  WORD_REJECTED,
  WORD_RETURNED,
  WORD_SEARCH,
  WORD_SEARCH_KEYWORD,
  WORD_SIGNATURE_STATUS,
  WORD_STATUS,
  WORD_SUBUNIT,
  WORD_UNIT,
} from "../../constants/translation";
const CommonSearch = ({
  pageName,
  page,
  limit,
  getData,
  setPage,
  rank,
  setRank,
  unit,
  setUnit,
  subUnit,
  setSubUnit,
  searchKey,
  setSearchKey,
  signatureStatus,
  setSignatureStatus,
  status,
  setStatus,
  pendingStatus,
  setPendingStatus,
  setSortAscending,
  setSortColumn,
  self,
  setSelf,
  archivedStatus,
  setArchivedStatus,
  dashboardStatus,
  setDashboardStatus,
  searchKeyPlaceholder,
  techArchivedStatus,
  setTechArchivedStatus
}) => {
  const dispatch = useDispatch();
  const userType = localStorage.getItem("user_type");

  const unit_list = useSelector(unit_details);
  const sub_unit_list = useSelector(subunit_details);
  const rankList = useSelector(rank_details);
  const [loader, setLoader] = useState(false);

  const signature_status_list = [
    { value: "1", label: WORD_AVAILABLE },
    { value: "0", label: WORD_NOT_AVAILABLE },
  ];

  const closed_status_list = [
    { value: "approved", label: WORD_APPROVED },
    { value: "rejected", label: WORD_REJECTED },
  ];

  const pending_status_list = [
    { value: "pending", label: WORD_PENDING },
    { value: "returned", label: WORD_RETURNED },
  ];

  const archived_status_list = [
    { value: "approved", label: WORD_APPROVED },
    { value: "rejected", label: WORD_REJECTED },
    { value: "pending", label: WORD_PENDING },
    { value: "returned", label: WORD_RETURNED },
  ];

  const dashboard_status_list = [
    { value: "approved", label: WORD_APPROVED },
    { value: "rejected", label: WORD_REJECTED },
    { value: "pending", label: WORD_PENDING },
    { value: "returned", label: WORD_RETURNED },
  ];

  const tech_archived_status_list = [
    { value: "Pending", label: WORD_PENDING },
    { value: "Closed", label: WORD_CLOSEDـSTATUS },
  ];


  useEffect(() => {
    dispatch(getRankData());
    dispatch(getAllUnits());
  }, []);

  const onUnitSelecthandler = (value) => {
    setUnit(value);
    setSubUnit("");
    dispatch(updateRedux({ key: "subunit_details", value: [] }));
    if (value?._id?.length) {
      dispatch(getAllSubUnits(value._id));
    }
  };

  const onSearchHandler = () => {
    setLoader(true);
    setPage(0);
    if (archivedStatus != undefined) {
      setSortColumn("deleted_at");
    } else {
      setSortColumn("createdAt");
    }
    setSortAscending(false);
    let formData = new FormData();
    formData.append("unit", unit?._id ?? "");
    formData.append("subunit", subUnit?._id ?? "");
    formData.append("rank", rank?._id ?? "");
    formData.append("keyword", searchKey);
    formData.append("perPage", limit);
    formData.append("page", 0);
    if (signatureStatus != undefined) {
      formData.append("is_available", signatureStatus?.value ?? "");
    }
    if (status != undefined) {
      formData.append("status", status?.value ?? "");
    }
    if (pendingStatus != undefined) {
      formData.append("status", pendingStatus?.value ?? "");
    }
    if (archivedStatus != undefined) {
      formData.append("status", archivedStatus?.value ?? "");
    }
    if (dashboardStatus != undefined) {
      formData.append("currentStatus", dashboardStatus?.value ?? "");
    }
    if (techArchivedStatus != undefined) {
      formData.append("status", techArchivedStatus?.value ?? "");
    }
    if (self !== "") {
      formData.append("self_value", self);
    }
    formData.append(
      "sort_key",
      archivedStatus != undefined ? "createdAt" : "deleted_at"
    );
    formData.append("sort_order", -1);
    dispatch(
      getData(formData, () => {
        setLoader(false);
      })
    );
  };

  const onCancelHandler = () => {
    setSearchKey("");
    setRank("");
    setUnit("");
    setSubUnit("");
    setPage(0);
    if (archivedStatus != undefined) {
      setSortColumn("deleted_at");
    } else {
      setSortColumn("createdAt");
    }
    setSortAscending(false);
    let formData = new FormData();
    formData.append("unit", "");
    formData.append("subunit", "");
    formData.append("rank", "");
    formData.append("keyword", "");
    formData.append("perPage", limit);
    formData.append("page", 0);
    if (signatureStatus != undefined) {
      setSignatureStatus("");
      formData.append("is_available", "");
    }
    if (status != undefined) {
      setStatus("");
      formData.append("status", "");
    }
    if (pendingStatus != undefined) {
      setPendingStatus("");
      formData.append("status", "");
    }
    if (archivedStatus != undefined) {
      setArchivedStatus("");
      formData.append("status", "");
    }
    if (dashboardStatus != undefined) {
      setDashboardStatus("");
      formData.append("currentStatus", "");
    }
    if (techArchivedStatus != undefined) {
      setTechArchivedStatus("");
      formData.append("status", "");
    }
    if (self !== "") {
      formData.append("self_value", self);
    }
    formData.append(
      "sort_key",
      archivedStatus != undefined ? "deleted_at" : "createdAt"
    );
    formData.append("sort_order", -1);
    dispatch(getData(formData));
  };

  return (
    <div className="searchBar">
      <div className="container">
        <div className="row">
          {pageName == "dashboard" && userType !== "super_admin" ? (
            <></>
          ) : (
            <>
              {rank != undefined ? (
                <div className="col pl-0 ">
                  <div className="form-group">
                    <label htmlFor="">{WORD_RANK}</label>
                    <Select
                      name="rank"
                      options={rankList}
                      hideSelectedOptions={false}
                      value={rank}
                      getOptionLabel={(option) => option.rank}
                      getOptionValue={(option) => option._id}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => setRank(e)}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {unit != undefined ? (
                <div className="col pl-0 ">
                  <div className="form-group">
                    <label htmlFor="">{WORD_UNIT}</label>
                    <Select
                      name="unit"
                      options={unit_list}
                      hideSelectedOptions={false}
                      value={unit}
                      getOptionLabel={(option) => option.unit_name}
                      getOptionValue={(option) => option._id}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => onUnitSelecthandler(e)}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {subUnit != undefined ? (
                <div className="col pl-0 ">
                  <div className="form-group">
                    <label htmlFor="">{WORD_SUBUNIT}</label>
                    <Select
                      name="subunit"
                      options={sub_unit_list}
                      hideSelectedOptions={false}
                      isDisabled={unit?.length != "" ? false : true}
                      value={subUnit}
                      getOptionLabel={(option) => option.subunit_name}
                      getOptionValue={(option) => option._id}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => setSubUnit(e)}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <div className="row justify-content-between">
          {userType == "super_admin" ? (
            <>
              {dashboardStatus != undefined ? (
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">{WORD_STATUS}</label>
                    <Select
                      name="subunit"
                      options={dashboard_status_list}
                      hideSelectedOptions={false}
                      value={dashboardStatus}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setDashboardStatus(e);
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <></>
          )}

          {signatureStatus != undefined ? (
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">{WORD_SIGNATURE_STATUS}</label>
                <Select
                  name="subunit"
                  options={signature_status_list}
                  hideSelectedOptions={false}
                  value={signatureStatus}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => setSignatureStatus(e)}
                />
              </div>
            </div>
          ) : null}
          {status != undefined ? (
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">{WORD_STATUS}</label>
                <Select
                  name="subunit"
                  options={closed_status_list}
                  hideSelectedOptions={false}
                  value={status}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setStatus(e);
                    localStorage.removeItem("dashboardSearch");
                  }}
                />
              </div>
            </div>
          ) : null}
          {pendingStatus != undefined ? (
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">{WORD_STATUS}</label>
                <Select
                  name="subunit"
                  options={pending_status_list}
                  hideSelectedOptions={false}
                  value={pendingStatus}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setPendingStatus(e);
                    localStorage.removeItem("dashboardSearch");
                  }}
                />
              </div>
            </div>
          ) : null}
          {archivedStatus != undefined ? (
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">{WORD_STATUS}</label>
                <Select
                  name="subunit"
                  options={archived_status_list}
                  hideSelectedOptions={false}
                  value={archivedStatus}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setArchivedStatus(e);
                    localStorage.removeItem("dashboardSearch");
                  }}
                />
              </div>
            </div>
          ) : null}
          {techArchivedStatus != undefined ? (
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">{WORD_STATUS}</label>
                <Select
                  name="subunit"
                  options={tech_archived_status_list}
                  hideSelectedOptions={false}
                  value={techArchivedStatus}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setTechArchivedStatus(e);
                    localStorage.removeItem("dashboardSearch");
                  }}
                />
              </div>
            </div>
          ) : null}
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor=""> </label>
              <div className="form-group position-relative mt-1">
                <input
                  type="text"
                  placeholder={searchKeyPlaceholder ?? WORD_SEARCH_KEYWORD}
                  className="form-control"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <img
                  src="./assets/icons/search.svg"
                  className="search-icon"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor=""> </label>
              <div className="form-group d-flex gap-2 mt-1">
                <button
                  className="btn btn-primary w-50"
                  onClick={() => onSearchHandler()}
                  disabled={loader}
                >
                  {WORD_SEARCH}
                  {loader && (
                    <img
                      className="me-3"
                      src="assets/loader/loader-white.svg"
                      alt=""
                    />
                  )}
                </button>
                <button
                  className="btn btn-danger w-50"
                  onClick={() => onCancelHandler()}
                  disabled={loader}
                >
                  {WORD_CLEAR}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex gap-2"></div>
        </div>
      </div>
    </div>
  );
};

export default CommonSearch;
