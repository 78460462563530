import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { loginValidation } from "../utils/validation";
import { getModulePermission, login } from "../redux/actionCreator";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  WORD_PASSWORD,
  WORD_SIGN_INTO,
  WORD_USERNAME,
  WORD_SIGN_IN,
  WORD_LOGGED_SUCCESS,
} from "../constants/translation";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [inputErrors, setInputErrors] = useState({});
  const [loader, setLoader] = useState(false)
  const pwdInputRef = useRef()

  async function loginHandler() {
    const isValidated = await loginValidation(
      {
        username: inputs.username,
        password: inputs.password,
      },
      setInputErrors
    );

    if (isValidated) {
      const data = {
        username: inputs.username,
        password: inputs.password,
      };
      setLoader(true)
      dispatch(
        login(data, (res) => {
          if (res.status) {
            // console.log("1")
            toast.success(WORD_LOGGED_SUCCESS, {
              position: "bottom-center",
              autoClose: 3000,
            });
            // dispatch(getModulePermission())
            // navigate('/')
            dispatch(
              getModulePermission((data) => {
                // console.log(data, "12")
                if (data?.includes("create_request")) {
                  // console.log("1234")
                  navigate("/");
                } else {
                  // console.log("1425")
                  navigate("/manage_requests/pending_requests");
                }
                setLoader(false)
              })
            );
            // window.location.reload()
          }
          setLoader(false)
        })
      );
    }
  }

  function usernameKeyDownHandler(e) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      pwdInputRef.current.focus()
    }
  }
  function keyDownHandler(e) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      loginHandler();
    }
  }

  return (
    <div id="Login">
      <div className="login-box">
        {/* <img
          className="dubai-police-logo"
          src="./assets/icons/dubai-police.svg"
          alt=""
        /> */}
        <div className="main__logo">
          <img className="tsd-logo" src="./assets/icons/tsd-logo.svg" alt="" />
          <img className="tsd-logo" src="./assets/images/government-of-dubai.png" style={{ width: "135px" }} alt="" />
        </div>
        <p className="header">{WORD_SIGN_INTO}</p>
        <span>
          <input
            type="text"
            placeholder={WORD_USERNAME}
            onKeyDown={(e) => usernameKeyDownHandler(e)}
            className={inputErrors?.username ? "error" : ""}
            onChange={(e) =>
              setInputs(
                (s) => (setInputErrors(), { ...s, username: e.target.value })
              )
            }
          />
          {inputErrors?.username && (
            <p className="error-message">{inputErrors.username}</p>
          )}
        </span>
        <span>
          <input
            type="password"
            ref={pwdInputRef}
            placeholder={WORD_PASSWORD}
            onKeyDown={(e) => keyDownHandler(e)}
            className={inputErrors?.password ? "error" : ""}
            onChange={(e) =>
              setInputs(
                (s) => (setInputErrors(), { ...s, password: e.target.value })
              )
            }
          />
          {inputErrors?.password && (
            <p className="error-message">{inputErrors.password}</p>
          )}
        </span>
        <button onClick={loginHandler} disabled={loader}>
          {loader && <img className="ms-4" src="assets/loader/loader-white.svg" alt="" />}
          {WORD_SIGN_IN}</button>
      </div>
    </div>
  );
}
