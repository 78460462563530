import React, { useContext } from "react";
import "./FormRequestDetails.scss";
import { RequestInputContext } from "../../pages/ManageRequests/CreateRequest";
import {
  WORD_DESCRIPTION,
  WORD_ENTER_DESCRIPTION,
  WORD_ENTER_TITLE,
  WORD_TITLE,
} from "../../constants/translation";

export default function FormRequestDetails() {
  const { inputs, setInputs, inputErrors, setInputErrors, initialInputObject } =
    useContext(RequestInputContext);

  return (
    <div id="FormRequestDetails" className="form">
      <span>
        <label htmlFor="">{WORD_TITLE}</label>
        <input
          type="text"
          placeholder={WORD_ENTER_TITLE ? WORD_ENTER_TITLE : "Enter Title"}
          className={inputErrors?.title ? "error" : ""}
          value={inputs.title}
          onChange={(e) => (
            setInputs((s) => ({ ...s, title: e.target.value })),
            setInputErrors(initialInputObject())
          )}
        />
        {inputErrors?.title && (
          <p className="error-message">{inputErrors.title}</p>
        )}
      </span>
      <span>
        <label htmlFor="">{WORD_DESCRIPTION}</label>
        <textarea
          cols=""
          rows="8"
          placeholder={WORD_ENTER_DESCRIPTION}
          className={inputErrors?.description ? "error" : ""}
          value={inputs.description}
          onChange={(e) => (
            setInputs((s) => ({ ...s, description: e.target.value })),
            setInputErrors(initialInputObject())
          )}
        />
        {inputErrors?.description && (
          <p className="error-message">{inputErrors.description}</p>
        )}
      </span>
    </div>
  );
}
