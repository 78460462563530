import React, { useEffect, useState } from "react";
import {
  getAllSubUnits,
  getAllUnits,
  getModules,
  getMultiSubunitData,
  getRankData,
  getUserList,
  insertUserRoles,
  manageRoles,
  searchRoleUser,
} from "../../redux/actionCreator";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Table } from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  modules,
  multiSubunitLists,
  rank_details,
  roleUsers,
  subunit_details,
  unit_details,
  updateRedux,
} from "../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  WORD_ADD_ROLE,
  WORD_BACK,
  WORD_CANCEL,
  WORD_CLEAR,
  WORD_CREATE,
  WORD_EMAIL,
  WORD_EMPLOYEE_ID,
  WORD_KEYWORD,
  WORD_NAME,
  WORD_NO_DATA_FOUND,
  WORD_PLEASE_ENTER_SEARCH_DATA,
  WORD_PLEASE_SELECT_ROLE,
  WORD_PLEASE_SELECT_USER,
  WORD_RANK,
  WORD_ROLES_ADDED_SUCCESFULLY,
  WORD_SEARCH,
  WORD_SEARCH_NAME_ID_USERNAME_JOBTITLE,
  WORD_SELECT_ALL,
  WORD_SELECT_USER_ROLE,
  WORD_SUBUNIT,
  WORD_UNIT,
  WORD_USERNAME,
} from "../../constants/translation";
import Pagination from "../../components/CommonPagination/Pagination";

export default function AddRole() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const units = useSelector(unit_details);
  const subUnitList = useSelector(multiSubunitLists);
  const rankList = useSelector(rank_details);
  const fullUserList = useSelector(roleUsers);
  const moduleList = useSelector(modules);

  const [subunit, setSubunit] = useState([]);
  const [unit, setUnit] = useState([]);
  const [rank, setRank] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [userId, setUserId] = useState([]);
  const [page, setPage] = useState(0);
  const [user, setUser] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [disableButtons, setDisabledButtons] = useState([]);
  const [nodata, setNodata] = useState(false);
  const [module, setModule] = useState([]);

  useEffect(() => {
    dispatch(getRankData());
    dispatch(getAllUnits());
    dispatch(getModules());
  }, []);

  useEffect(() => {
    if (fullUserList) {
      pageDataHandler(page);
      setPageCount(Math.ceil(fullUserList?.length / 10));
    }
  }, [fullUserList]);

  const selectAllUserHandler = (e) => {
    if (e.target.checked) {
      let id = [];
      fullUserList?.forEach((ele) => {
        id.push(ele._id);
      });
      setUserId([...id]);
    } else {
      setUserId([]);
    }
  };

  const onUnitSelecthandler = (value) => {
    setUnit(value);
    let formData = new FormData();
    value?.forEach((item) => formData.append("unit[]", item._id));
    dispatch(
      getMultiSubunitData(formData, (data) => {
        let temp;
        temp = data?.filter((el) => {
          return subunit?.find((element) => {
            return element._id == el._id;
          });
        });
        setSubunit(temp);
      })
    );
  };

  const onClearHandler = () => {
    setKeyword("");
    setUnit([]);
    setSubunit([]);
    setRank([]);
  };

  const onSearchHandler = (pag) => {
    // setNodata(true);
    if (
      keyword?.length == 0 &&
      unit?.length == 0 &&
      subunit?.length == 0 &&
      rank?.length == 0
    ) {
      toast.warning(WORD_PLEASE_ENTER_SEARCH_DATA, {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      let formData = new FormData();
      formData.append("keyword", keyword);
      unit?.forEach((item) => formData.append("unit[]", item._id));
      subunit?.forEach((item) => formData.append("subunit[]", item._id));
      rank?.forEach((item) => formData.append("rank[]", item._id));
      dispatch(
        searchRoleUser(formData, (data) => {
          if (data == 0) {
            setNodata(true);
          } else {
            setNodata(false);
          }
        })
      );
    }
  };

  const pageDataHandler = (pag) => {
    setUser(fullUserList.slice(pag * 10, pag * 10 + 10));
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      pageDataHandler(Number(e.selected));
    }
  };

  const onCancelHandler = () => {
    setSubunit([]);
    setUnit([]);
    setRank([]);
    setModule([]);
    setKeyword([]);
    setUserId([]);
    navigate("/manage_user_roles/assign_role");
  };

  const onCreateRolesHandler = (e) => {
    e.preventDefault();
    if (module?.length == 0) {
      toast.warning(WORD_PLEASE_SELECT_ROLE, {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else if (userId?.length == 0) {
      toast.warning(WORD_PLEASE_SELECT_USER, {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      dispatch(updateRedux({ key: "submitLoader", value: true }));
      let formData = new FormData();
      module.forEach((item) => formData.append("modules[]", item));
      userId.forEach((item) => formData.append("users[]", item));
      console.log(formData);
      dispatch(
        insertUserRoles(formData, (status) => {
          if (status == true) {
            toast.success(WORD_ROLES_ADDED_SUCCESFULLY, {
              position: "bottom-center",
              autoClose: 3000,
            });
            setSubunit([]);
            setUnit([]);
            setRank([]);
            setModule([]);
            setKeyword([]);
            setUserId([]);
            setPageCount(null);
            setUser([]);
            setPage(0);
            dispatch(updateRedux({ key: "users", value: [] }));
            setNodata(false);
            dispatch(updateRedux({ key: "roleUsers", value: [] }));

            navigate("/manage_user_roles/assign_role");
          }
          dispatch(updateRedux({ key: "submitLoader", value: false }));
        })
      );
    }
  };

  return (
    <div id="AssignRole">
      <h1 className="page-title">{WORD_ADD_ROLE}</h1>
      <span className="button text-left">
        <button
          className="btn btn-secondary"
          onClick={() => navigate("/manage_user_roles/assign_role")}
        >
          {WORD_BACK}
        </button>
      </span>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-6">
            <Form.Group controlId="formSelectMultiple" className="mb-3">
              <Form.Label>{WORD_RANK}</Form.Label>
              <Select
                isMulti
                name="rank"
                options={rankList}
                hideSelectedOptions={false}
                value={rank}
                getOptionLabel={(option) => option.rank}
                getOptionValue={(option) => option._id}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => setRank(e)}
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group controlId="formSelectMultiple" className="mb-3">
              <Form.Label>{WORD_UNIT}</Form.Label>
              <Select
                isMulti
                name="unit"
                options={units}
                hideSelectedOptions={false}
                value={unit}
                getOptionLabel={(option) => option.unit_name}
                getOptionValue={(option) => option._id}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => onUnitSelecthandler(e)}
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group controlId="formSelectMultiple" className="mb-3">
              <Form.Label>{WORD_SUBUNIT}</Form.Label>
              <Select
                isMulti
                name="subunit"
                options={subUnitList}
                hideSelectedOptions={false}
                isDisabled={unit?.length ? false : true}
                value={subunit}
                getOptionLabel={(option) => option.subunit_name}
                getOptionValue={(option) => option._id}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => setSubunit(e)}
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <Form.Label>{WORD_KEYWORD}</Form.Label>
              <Form.Control
                type="text"
                value={keyword}
                placeholder={WORD_SEARCH_NAME_ID_USERNAME_JOBTITLE}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6"></div>
          <Col lg={12}>
            <div className="d-flex gap-2">
              <Button
                className="btn btn-primary mr-2"
                onClick={(e) => onSearchHandler(page)}
                disabled={disableButtons?.includes("searchButton")}
              >
                {WORD_SEARCH}
              </Button>
              <Button
                className="btn btn-secondary mr-2"
                onClick={() => onClearHandler()}
              >
                {WORD_CLEAR}
              </Button>
            </div>
          </Col>
        </div>
      </div>
      {user?.length ? (
        <div className="selected__users">
          <Table striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th style={{ display: "flex" }}>
                  <Form.Check
                    type="checkbox"
                    id={`valueOne`}
                    style={{ marginLeft: "5px" }}
                    checked={fullUserList?.length == userId?.length}
                    onClick={(e) => selectAllUserHandler(e)}
                  />
                  {WORD_SELECT_ALL}
                </th>
                <th> {WORD_EMPLOYEE_ID}</th>
                <th> {WORD_RANK}</th>
                <th> {WORD_NAME}</th>
                <th> {WORD_UNIT}</th>
                <th> {WORD_SUBUNIT}</th>
                {/* <th> {WORD_NAME}</th>
                <th> {WORD_EMAIL}</th>
                <th> {WORD_USERNAME}</th> */}
              </tr>
            </thead>
            <tbody>
              {user?.map((ele, i) => {
                return (
                  <tr>
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={`valueOne`}
                        checked={userId.includes(ele._id) ? true : false}
                        onClick={(e) =>
                          e.target.checked
                            ? setUserId([...userId, ele._id])
                            : setUserId(
                                userId.filter((item) => item != ele._id)
                              )
                        }
                      />
                    </td>
                    <td>{ele.employee_id}</td>
                    <td>{ele.rank[0]?.rank}</td>
                    <td>{ele.name}</td>
                    <td>{ele.unit[0]?.unit_name}</td>
                    <td>{ele.subunit[0]?.subunit_name}</td>
                    {/* <td>{ele.name}</td>
                    <td>{ele.email}</td>
                    <td>{ele.username}</td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* <div className="paginationStyle">
            {pageCount > 1 ? (
              <ReactPaginate
                previousLabel={<>❮</>}
                nextLabel={<>❯</>}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                // breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                initialPage={page}
                onPageChange={(e) => handlePageChange(e)}
                containerClassName="pagination"
                activeClassName="pageActive"
              />
            ) : null}
          </div> */}
          <Pagination
            page={page}
            pageCount={pageCount}
            handlePageChange={handlePageChange}
          />
        </div>
      ) : (
        <>
          {nodata ? (
            <div className="noData">
              <h3>{WORD_NO_DATA_FOUND}</h3>
            </div>
          ) : null}
        </>
      )}
      <div className="col-md-12">
        <div className="">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="m-0 form__title">{WORD_SELECT_USER_ROLE}</h6>
          </div>
          <hr />
          <div className="row flex-wrap">
            {moduleList?.map((ele, i) => {
              return (
                <div className="col-3 select__box mb-5">
                  <Form.Check
                    type="checkbox"
                    id={`${i}`}
                    checked={module.includes(ele.key) ? true : false}
                    onClick={(e) =>
                      e.target.checked
                        ? setModule([...module, ele.key])
                        : setModule(module.filter((item) => item != ele.key))
                    }
                  />
                  <Form.Label for={`${i}`}>{ele.Value_arabic}</Form.Label>
                </div>
              );
            })}
          </div>

          <div className="">
            <div className="d-flex gap-2">
              <div className="col-md-12 mt-4">
                <button
                  className="btn btn-primary ml-2"
                  onClick={(e) => onCreateRolesHandler(e)}
                  disabled={disableButtons?.includes("submitButton")}
                >
                  {WORD_CREATE}
                </button>
                <Button
                  variant="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  {WORD_CANCEL}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
