import React, { useEffect, useState } from 'react'
import './Layout.scss'
import { Outlet, useLocation } from 'react-router-dom'
import NavigationBar from '../NavigationBar/NavigationBar'
import Header_Main_Layout from '../Header_Main_Layout/Header_Main_Layout'
import Header_Pdf_Layout from '../Header_Pdf_Layout/Header_Pdf_Layout'
import { useSelector } from 'react-redux'
import { PdfHeaderDetails } from '../../redux/commonReducer'

export default function Layout() {

    const location = useLocation()
    const pdfDetails = useSelector((s) => s.commonReducer.pdfHeaderDetails)
    const [isPdfLayout, setIsPdfLayout] = useState(false)

    useEffect(() => {
        if (location.pathname.includes('/document')) setIsPdfLayout(true)
        else setIsPdfLayout(false)
    }, [location.pathname])

    return (
        <div id='Layout'>
            <aside>
                <NavigationBar />
            </aside>
            <div>
                <header id='main-header'>
                    <Header_Main_Layout />
                    {isPdfLayout && <Header_Pdf_Layout pages={pdfDetails?.pages} signatories={pdfDetails?.signatories} />}
                </header>
                <main>
                    <Outlet />
                </main>
            </div>
        </div>
    )
}