import React, { useEffect, useRef, useState } from "react";
import "./PDFviewer.scss";
import { Document, Page, pdfjs } from "react-pdf";
import { capitalize } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, setPdfHeaderSelectedBtn } from "../../redux/actionCreator";
import { WORD_DELETE, WORD_SIGNATORY } from "../../constants/translation";
import { API_BASE_URL } from "../../constants/configuration";
import { updateRedux } from "../../redux/commonReducer";
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//console.log("ddd",__dirname)
//pdfjs.GlobalWorkerOptions.workerSrc ="http://localhost:3000/pdf.worker.js"
pdfjs.GlobalWorkerOptions.workerSrc = "./pdf.worker";
export let onButtonClick;
export let pageChangeHandler;
export default function PDFviewer({ url, setPagesCount, onChange, loaderFunc }) {
  // let A4size = { width: 2480 / 2.2568, height: 3508 / 2.2568 }
  let A4size = { width: 595 * 1.85, height: 842 * 1.85 };

  const dispatch = useDispatch();
  const selectedBtns = useSelector((s) => s.commonReducer.pdfHeaderBtns);
  const [boxes, setBoxes] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [pages, setPages] = useState({ current: 1, totalPages: 1 });
  const [templateBox, setTemplateBox] = useState();
  const [loader, setLoader] = useState(false);

  const BoxModel = useRef();

  onButtonClick = async (sl_no, name, page, signatory_id) => {
    // let result = boxes.some(
    //   (box) => box.signatory_id === signatory_id && box.page === page
    // );
    // if (result) return;
    let userSignature = ''
    let userRank = ''
    await getUserData(signatory_id._id, (user) => {
      userRank = user.userData.rank[0].rank
      userSignature = user.userData.signature
    })

    let box = templateBox;
    let boxUUID = new Date().valueOf()
    let newBox = box.cloneNode(true);
    newBox.id += sl_no;
    newBox.dataset.id = boxUUID
    newBox.style.display = "flex";
    newBox.style.left = "0px";
    newBox.style.bottom = "0px";
    newBox.querySelector("span").addEventListener("click", (e) => boxDeleteHandler(e));
    newBox.addEventListener("mousedown", (e) => e.target.classList.add("dragging"));
    newBox.addEventListener("touchstart", (e) => e.target.classList.add("dragging"));
    newBox.addEventListener("mouseup", (e) => e.target.classList.remove("dragging"));
    newBox.addEventListener("touchend", (e) => e.target.classList.remove("dragging"));
    newBox.childNodes[1].src = API_BASE_URL + 'signature/' + userSignature
    newBox.childNodes[0].childNodes[0].textContent = userRank;
    newBox.childNodes[0].childNodes[1].textContent = capitalize(name);

    let boxesTemp = [...boxes,
    {
      id: boxUUID,
      element: newBox,
      x: 0,
      y: 0,
      sl_no: sl_no,
      signatory_id: signatory_id,
      page: pages.current,
    }
    ]
    setBoxes(boxesTemp);
    dispatch(setPdfHeaderSelectedBtn({ ...selectedBtns, [sl_no]: true }, boxesTemp));
    const scrollHeight = document.documentElement.scrollHeight;
    window.scrollTo(0, scrollHeight);
    return boxesTemp
  };

  pageChangeHandler = (value) => {
    // console.log(value)
    if (value === -1 && pages.current === 1) return;
    if (value === 1 && pages.current === pages.totalPages) return;
    setPages((s) => ({ ...s, current: s.current + value }));
  };
  useEffect(() => {
    if (boxes.length === 0) {
      dispatch(updateRedux({ key: 'selectedBoxes', value: [] }))
    }
  }, [boxes])

  useEffect(() => {
    setPagesCount(pages);
  }, [pages]);

  function onMouseMove(e) {
    if (isDragging && e.target.id.includes("sign-box")) {
      let x = parseInt(e.target.style.left) + e.movementX + "px";
      let y = parseInt(e.target.style.bottom) - e.movementY + "px";
      let leftEnd = e.target.parentElement.offsetWidth - e.target.offsetWidth;
      let topEnd = e.target.parentElement.offsetHeight - e.target.offsetHeight;
      e.target.style.left =
        parseInt(x) < 0 ? 0 : parseInt(x) > leftEnd ? leftEnd : x;
      e.target.style.bottom =
        parseInt(y) < 0 ? 0 : parseInt(y) > topEnd ? topEnd : y;

      setBoxes((s) =>
        s.map((box, i) => {
          if (box.id === parseInt(e.target.dataset.id))
            return { ...box, x, y };
          return box;
        })
      );
    }
  }


  function onPdfLoadingSuccess(pdf) {
    // setLoader(true);
    setPages((s) => ({ ...s, totalPages: pdf.numPages }));
    loaderFunc(false)
    // console.log(pdf)
  }

  // console.log(boxes, 'boxes')
  function boxDeleteHandler(e) {
    e.target.parentElement.remove();
    setBoxes(s => {
      let remainingBoxes = s.filter((obj, i) => obj.element.dataset.id !== e.target.parentElement.dataset.id)
      let boxNumber = e.target.parentElement.id.slice(-1)
      dispatch(setPdfHeaderSelectedBtn({ ...selectedBtns, boxNumber: false }, remainingBoxes));
      return remainingBoxes
    });

  }

  document.addEventListener("mouseup", () => {
    setIsDragging(false);
    let outerBox = document.getElementById("boxes");
    if (outerBox) {
      const childElements = outerBox.children;
      for (let i = 0; i < childElements.length; i++) {
        childElements[i].classList.remove("dragging");
      }
    }
  });
  document.addEventListener("touchend", () => {
    setIsDragging(false);
    let outerBox = document.getElementById("boxes");
    if (outerBox) {
      const childElements = outerBox.children;
      for (let i = 0; i < childElements.length; i++) {
        childElements[i].classList.remove("dragging");
      }
    }
  });

  useEffect(() => {
    let outerBox = document.getElementById("boxes");
    setTemplateBox(BoxModel.current);
    const timer = setTimeout(() => {
      outerBox.replaceChildren();
      boxes.forEach((box) => {
        if (outerBox) {
          if (box.page === pages.current) {
            outerBox.appendChild(box.element);
          }
        }
      });
      onChange(boxes);
    }, 500);
    return () => clearTimeout(timer);
  }, [boxes, pages]);

  // console.log(pages,boxes)
  // console.log('boxes', boxes)
  // console.log('draggin', isDragging)
  // console.log(boxes.filter(box => box.page === pages.current))

  return (
    <div id="PDFviewer">
      <div
        className="wrapper"
        style={{ width: A4size.width, height: A4size.height }}
      >
        <div className="pdf-preview-div">
          <Document file={url} onLoadSuccess={onPdfLoadingSuccess}>
            <Page pageNumber={pages.current} renderTextLayer={false} />
          </Document>
        </div>
        <div
          id="boxes"
          onMouseDown={() => setIsDragging(true)}
          onMouseUp={() => setIsDragging(false)}
          onMouseMove={onMouseMove}
          onTouchStart={() => setIsDragging(true)}
          onTouchEnd={() => setIsDragging(false)}
          onTouchMove={onMouseMove}
        >
          <div ref={BoxModel} id="sign-box" className="sign-box">
            <div>
              <p className="rank"></p>
              <p className="name"></p>
            </div>
            <img src="" alt="" />
            <hr />
            <span>
              {WORD_DELETE}
              <img src="./assets/icons/delete1 green.svg" alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export const SignatureButton = ({ sl_no, name, page, signatory_id }) => {
  const selectedBtns = useSelector((s) => s.commonReducer.pdfHeaderBtns);
  const selectedBoxes = useSelector((s) => s.commonReducer.selectedBoxes);
  const [counts, setCounts] = useState({ total: 0 })
  const [loader, setLoader] = useState(false)


  function buttonClickHandler() {
    setLoader(true)
    onButtonClick(sl_no, name, page, signatory_id).then(res => {
      countsGetter(res)
      setLoader(false)
    })
  }

  function countsGetter(boxesArray) {
    let count = 1
    let isCurrPageAvailable = false
    const resObj = boxesArray?.reduce((acc, crr) => {
      if (crr.signatory_id._id === signatory_id._id) {
        if (crr.page === page) {
          isCurrPageAvailable = true
          return { ...acc, total: acc.total + 1, [page]: count++ }
        } else return acc
      } else return acc
    }, counts)
    if (boxesArray?.length > 0) setCounts(resObj)
    else setCounts({ total: 0 })
    if (!isCurrPageAvailable) setCounts(s => ({ ...s, [page]: null }))
  }

  useEffect(() => {
    countsGetter(selectedBoxes)
    return () => setCounts({ total: 0 })
  }, [selectedBoxes])

  return (
    <button
      className={counts[page] ? " active" : ""}
      onClick={() => buttonClickHandler()}
    >
      {loader ? <img src="assets/loader/loader.svg" alt="" />
        : <span>{counts[page] && counts[page]}</span>
      }
      <span>{capitalize(name)}</span>
    </button>
  );
};
