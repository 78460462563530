import React, { useContext } from "react";
import "./RequestDetailsEntry.scss";
import PageSubTitle from "../PageSubTitle/PageSubTitle";
import FormRequestDetails from "../FormRequestDetails/FormRequestDetails";
// import ButtonsAtachment from '../ButtonsAtachment/ButtonsAtachment'
import ButtonsFooter from "../ButtonsFooter/ButtonsFooter";
import { requestDetailEntryValidation } from "../../utils/validation";
import { RequestInputContext } from "../../pages/ManageRequests/CreateRequest";
import { toast } from "react-toastify";
import {
  WORD_ENTER_REQUEST_DETAILS,
  WORD_FILE_FORMAT_NOT_SUPPORTED,
  WORD_ONLY_PDF_CAN_UPLOAD,
} from "../../constants/translation";
import { useNavigate } from "react-router-dom";

export default function RequestDetailsEntry({ setPage, navigate, setSignatoryCount, setPrevData, handleData }) {
  const { inputs, setInputs, inputErrors, setInputErrors, initialInputObject } = useContext(RequestInputContext);

  async function nextPageHandler() {
    // console.log({ inputs })
    // console.log({ inputErrors })

    let isValidated = await requestDetailEntryValidation(
      {
        title: inputs?.title,
        description: inputs?.description,
        pdf: inputs?.pdf,
      },
      setInputErrors
    );
    if (inputs?.pdf?.type !== "application/pdf" && typeof inputs?.pdf !== 'string') {
      toast.error(WORD_ONLY_PDF_CAN_UPLOAD, {
        position: "bottom-center",
        autoClose: 3000,
      });
      return;
    }
    let filesValidated = true
    inputs?.supportingFiles?.length > 0 && Array.from(inputs?.supportingFiles).forEach(item => {
      let supportedFormats = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/jpeg', 'image/png', 'image/jpg', 'text/csv', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/msword']
      if (!supportedFormats.includes(item.type)) {
        filesValidated = false
      }
    })
    if (!filesValidated) {
      toast.error(WORD_FILE_FORMAT_NOT_SUPPORTED, {
        position: "bottom-center",
        autoClose: 3000,
      });
      return
    }
    isValidated && setPage(2);
  }
  return (
    <div id="RequestDetailsEntry">
      <section className="title">
        <PageSubTitle name={WORD_ENTER_REQUEST_DETAILS} />
      </section>
      <section>
        <FormRequestDetails />
      </section>
      <section>
        <ButtonsFooter
          page={"RequestDetailsEntry"}
          onCancel={async () => (
            await setInputErrors(initialInputObject()),
            setInputs(initialInputObject()),
            setPage(1),
            setSignatoryCount({ id: 1, value: 1 }),
            setPrevData(null),
            handleData({ id: 1, value: 1 }, initialInputObject()),
            navigate("/manage_requests/create_request", {
              state: { id: "", back: false },
            })
          )}
          onNext={nextPageHandler}
        />
      </section>
    </div>
  );
}
