import React, { Fragment, useContext, useEffect, useState } from "react";
import "./SignatoriesEntry.scss";
import FormAddPerson from "../FormAddPerson/FormAddPerson";
import SetPersonCount from "../SetPersonCount/SetPersonCount";
import PageSubTitle from "../PageSubTitle/PageSubTitle";
import ButtonsFooter from "../ButtonsFooter/ButtonsFooter";
import { useNavigate } from "react-router-dom";
import { RequestInputContext } from "../../pages/ManageRequests/CreateRequest";
import { createRequest, updateRequest } from "../../redux/actionCreator";
import { toast } from "react-toastify";
import { singnatoriesEntryValidation } from "../../utils/validation";
import {
  WORD_ALL_SIGNATORIES_NOT_SELECTED,
  WORD_REQUEST_ADDED_SUCCESSFULLY,
  WORD_REQUEST_UPDATED_SUCCESSFULLY,
  WORD_SIGNATORIES,
  WORD_SIGNATORY_NAME_NOT_SELECTED,
} from "../../constants/translation";
import { updateRedux } from "../../redux/commonReducer";
import { useDispatch } from "react-redux";

export default function SignatoriesEntry({
  setPage,
  PreFilledData,
  setPrevData,
  signatoryCount,
  setSignatoryCount,
  handleData
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { inputs, setInputs, inputErrors, setInputErrors, initialInputObject } =
    useContext(RequestInputContext);


  const [updater, setUpdater] = useState(true);

  // useEffect(() => {
  //   console.log(inputs,"2")
  //   let inputdata = inputs
  //   if(inputdata?.Signatory?.length !== parseInt(signatoryCount.id)) {
  //     console.log(inputdata?.Signatory?.slice(0, parseInt(signatoryCount.id)), "11", inputdata?.Signatory, parseInt(signatoryCount.id))
  //     inputdata.Signatory = inputdata?.Signatory?.slice(0, parseInt(signatoryCount.id))
  //     console.log(inputdata, parseInt(signatoryCount.id))
  //     setInputs(inputdata)
  //   }
  // }, [signatoryCount])



  // useEffect(() => {
  //   if (PreFilledData) {
  //     console.log("p")
  //     let signatoriesCount = PreFilledData?.signatory?.length;
  //     signatoriesCount &&
  //       setSignatoryCount({ id: signatoriesCount, value: signatoriesCount }) &&
  //       handleData({ id: signatoriesCount, value: signatoriesCount });
  //   }
  // }, [PreFilledData]);
  // console.log({ inputs })

  useEffect(() => {
    dispatch(updateRedux({ key: "pdf_open_status", value: true }));
  }, [])

  function onSaveHandler() {
    if (inputs.Signatory.length !== parseInt(signatoryCount.id)) {
      toast.error(WORD_ALL_SIGNATORIES_NOT_SELECTED, {
        position: "bottom-center",
        autoClose: 3000,
      });
      return;
    }
    let errvalue = false;
    inputs.Signatory &&
      inputs.Signatory.forEach((item) => {
        if (item.name === undefined) {
          toast.error(WORD_SIGNATORY_NAME_NOT_SELECTED, {
            position: "bottom-center",
            autoClose: 3000,
          });
          errvalue = true;
        } else {
          errvalue = false;
        }
      });
    if (errvalue) {
      return;
    }
    inputs.Signatory.sort((a, b) => { if (a.sl_no > b.sl_no) return 1; if (a.sl_no < b.sl_no) return -1; else return 0 })
    const formdata = new FormData();
    formdata.append("title", inputs.title.trim());
    formdata.append("description", inputs.description.trim());
    formdata.append("pdf", inputs.pdf);
    formdata.append(`is_reviewer`, inputs.isReviewer);
    inputs.Signatory.forEach((item, i) => {
      formdata.append(`signatory[${i}]`, item.name);
      formdata.append(`comment_allowed[${i}]`, item.comment_allowed || false);
      formdata.append(`view_comment[${i}]`, item.view_comment || false);
      //formdata.append(`show_signature[${i}]`, item?.show_signature || false);
    });
    if (inputs?.supportingFiles) {
      Array.from(inputs.supportingFiles)?.forEach((item, i) => {
        formdata.append(`supporting_documents`, inputs?.supportingFiles[i]);
      })
    }

    formdata.append("secretery", inputs.Secretary[0]?.name);
    // console.log(formdata, 'formdata ')
    if (inputs.isEditing) {
      formdata.append("id", PreFilledData?._id);
      dispatch(updateRedux({ key: 'loader1', value: true }))
      updateRequest(formdata, (res) => {
        if (res.status) {
          toast.success(WORD_REQUEST_UPDATED_SUCCESSFULLY, {
            position: "bottom-center",
            autoClose: 3000,
          });
          dispatch(updateRedux({ key: 'loader1', value: false }))
          // console.log(res.request_details.signatory.length === 1, res.request_details.signatory[0].is_reviewer, 'response on update')
          if (res.request_details.signatory.length === 1 && res.request_details.signatory[0].is_reviewer) {
            navigate('/')
          } else navigate("document/" + res.request_details._id);
          dispatch(updateRedux({ key: "pdf_open_status", value: false }));
        }
      });
    } else {
      dispatch(updateRedux({ key: 'loader1', value: true }))
      createRequest(formdata, (res) => {
        if (res.status) {
          toast.success(WORD_REQUEST_ADDED_SUCCESSFULLY, {
            position: "bottom-center",
            autoClose: 3000,
          });
          dispatch(updateRedux({ key: 'loader1', value: false }))
          // console.log(inputs.Signatory.length === 1, inputs.isReviewer, 'response on create')
          if (inputs.Signatory.length === 1 && inputs.isReviewer) {
            navigate("/")
          } else navigate("document/" + res.requestdata._id);
          dispatch(updateRedux({ key: "pdf_open_status", value: false }));
        }
      });
    }
  }

  // console.log({ PreFilledData })
  // useEffect(() => {
  //     setUpdater(false)
  //     setInputs(s => ({ ...s, Signatory: [] }))
  //     setTimeout(() => {
  //         setUpdater(true)
  //     }, 100);
  // }, [signatoryCount])

  // console.log({ signatoryCount })

  return (
    (
      <div id="SignatoriesEntry">
        <section className="title">
          <PageSubTitle name={WORD_SIGNATORIES} />
        </section>
        <section>
          <SetPersonCount
            name={"signatories"}
            state={signatoryCount}
            setState={setSignatoryCount}
            handleData={handleData}
            inputs={inputs}
          />
        </section>
        {/* <hr /> */}
        {Array.from({ length: signatoryCount.id }).map((ele, i) => {
          return (
            <Fragment key={i}>
              <section>
                <FormAddPerson
                  name={"Signatory"}
                  serialNo={i + 1}
                  prevData={PreFilledData?.signatory[i]?.signatory}
                  setPrevData={setPrevData}
                  inputs={inputs}
                  index={i}
                />
              </section>
              <hr />
            </Fragment>
          );
        })}
        <section>
          <FormAddPerson
            name={"Secretary"}
            serialNo={1}
            prevData={PreFilledData?.secretery[0]?.secretery}
            setPrevData={setPrevData}
            inputs={inputs}
            index={0}
          />
        </section>
        <section>
          <ButtonsFooter
            page={"SignatoriesEntry"}
            onCancel={async () => (
              await setInputErrors(initialInputObject()),
              setInputs(initialInputObject()),
              setPage(1),
              setSignatoryCount({ id: 1, value: 1 }),
              handleData({ id: 1, value: 1 }, initialInputObject()),
              setPrevData(null),
              dispatch(updateRedux({ key: "pdf_open_status", value: false })),
              navigate("/manage_requests/create_request", {
                state: { id: "", back: false },
              })
            )}
            onBack={() => { setPage(1); dispatch(updateRedux({ key: "pdf_open_status", value: false })); }}
            onSave={onSaveHandler}
          />
        </section>
      </div>
    )
  );
}
