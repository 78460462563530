import React from 'react'

function NotFound() {
  return (
    <div className='Notfound'>
        <h1>Page Not Found!</h1>
    </div>
  )
}

export default NotFound