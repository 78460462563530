import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name: 'commonReducer',
    initialState: {
        value: true,
        pdf_open_status: false,
        loader1: false,
        dashboard_loader : false,
        pending_loader : false,
        inprogress_loader : false,
        closed_loader : false,
        archived_loader : false,
        report_loader : false,
        report_export_loader : false,
        signature_loader: false,
        role_loader : false,
        tech_pending_loader : false,
        tech_closed_loader : false,
        tech_report_loader: false,
        tech_detail_loader : false,
        tech_archived_loader : false
    },
    reducers: {
        updateRedux: (state, data) => {
            state[data.payload.key] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;


export const User = (state) => state.commonReducer.user;
export const unit_details = (state) => state?.commonReducer.unit_details;
export const subunit_details = (state) => state?.commonReducer.subunit_details;
export const userList = (state) => state?.commonReducer.userList;
export const request_details = (state) => state?.commonReducer.request_details;
export const request_total_pages = (state) => state?.commonReducer.request_total_pages;
export const request_page_status = (state) => state?.commonReducer.request_page_status;
export const request_page = (state) => state?.commonReducer.request_page;
export const pending_details = (state) => state?.commonReducer.pending_details;
export const pending_total_pages = (state) => state?.commonReducer.pending_total_pages;
export const pending_page_status = (state) => state?.commonReducer.pending_page_status;
export const pending_page = (state) => state?.commonReducer.pending_page;
export const closed_details = (state) => state?.commonReducer.closed_details;
export const closed_total_pages = (state) => state?.commonReducer.closed_total_pages;
export const closed_page_status = (state) => state?.commonReducer.closed_page_status;
export const closed_page = (state) => state?.commonReducer.closed_page;
export const dashboard_details = (state) => state?.commonReducer.dashboard_details;
export const dashboard_total_pages = (state) => state?.commonReducer.dashboard_total_pages;
export const dashboard_page_status = (state) => state?.commonReducer.dashboard_page_status;
export const dashboard_page = (state) => state?.commonReducer.dashboard_page;
export const dashboard_openCount = (state) => state?.commonReducer.dashboard_openCount;
export const dashboard_approvedCount = (state) => state?.commonReducer.dashboard_approvedCount;
export const dashboard_rejectedCount = (state) => state?.commonReducer.dashboard_rejectedCount;
export const dashboard_pendingCount = (state) => state?.commonReducer.dashboard_pendingCount;
export const dashboard_returnedCount = (state) => state?.commonReducer.dashboard_returnedCount;
export const dashboard_closedCount = (state) => state?.commonReducer.dashboard_closedCount;
export const dashboard_inprogressCount = (state) => state?.commonReducer.dashboard_inprogressCount;
export const rank_details = (state) => state?.commonReducer.rank_details;
export const roleUsers = (state) => state?.commonReducer.roleUsers;
export const modules = (state) => state?.commonReducer.modules;
export const multiSubunitLists = (state) => state?.commonReducer.multiSubunitLists;
export const userRoles = (state) => state?.commonReducer.userRoles;
export const rolesTotalCount = (state) => state?.commonReducer.rolesTotalCount;
export const role_page_status = (state) => state?.commonReducer.role_page_status;
export const editRoleDetails = (state) => state?.commonReducer.editRoleDetails;
export const permissionModules = (state) => state?.commonReducer.permissionModules
export const report_openCount = (state) => state?.commonReducer.report_openCount;
export const report_approvedCount = (state) => state?.commonReducer.report_approvedCount;
export const report_rejectedCount = (state) => state?.commonReducer.report_rejectedCount;
export const report_pendingCount = (state) => state?.commonReducer.report_pendingCount;
export const report_returnedCount = (state) => state?.commonReducer.report_returnedCount;
export const report_closedCount = (state) => state?.commonReducer.report_closedCount;
export const inprogress_details = (state) => state?.commonReducer.inprogress_details;
export const inprogress_total_pages = (state) => state?.commonReducer.inprogress_total_pages;
export const inprogress_page_status = (state) => state?.commonReducer.inprogress_page_status;
export const inprogress_page = (state) => state?.commonReducer.inprogress_page;
export const signatureData = (state) => state?.commonReducer.signatureData;
export const signature_totalCount = (state) => state?.commonReducer.signature_totalCount;
export const signature_page_status = (state) => state?.commonReducer.signature_page_status;
export const pdf_open_status = (state) => state?.commonReducer.pdf_open_status;
export const self_openCount = (state) => state?.commonReducer.self_openCount;
export const self_approvedCount = (state) => state?.commonReducer.self_approvedCount;
export const self_rejectedCount = (state) => state?.commonReducer.self_rejectedCount;
export const self_pendingCount = (state) => state?.commonReducer.self_pendingCount;
export const self_returnedCount = (state) => state?.commonReducer.self_returnedCount;
export const self_inprogressCount = (state) => state?.commonReducer.self_inprogressCount;
export const employee_id = (state) => state?.commonReducer.employee_id;

export const archived_list = (state) => state?.commonReducer.archived_list;
export const archived_total_pages = (state) => state?.commonReducer.archived_total_pages;
export const archived_page_status = (state) => state?.commonReducer.archived_page_status;
export const archived_page = (state) => state?.commonReducer.archived_page;

export const dashboard_loader = (state) => state?.commonReducer.dashboard_loader;
export const pending_loader = (state) => state?.commonReducer.pending_loader;
export const inprogress_loader = (state) => state?.commonReducer.inprogress_loader;
export const closed_loader = (state) => state?.commonReducer.closed_loader;
export const archived_loader = (state) => state?.commonReducer.archived_loader;
export const report_loader = (state) => state?.commonReducer.report_loader;
export const report_export_loader = (state) => state?.commonReducer.report_export_loader;
export const signature_loader = (state) => state?.commonReducer.signature_loader;
export const role_loader = (state) => state?.commonReducer.role_loader;
export const tech_pending_loader = (state) => state?.commonReducer.tech_pending_loader;
export const tech_closed_loader = (state) => state?.commonReducer.tech_closed_loader;

export const tech_pending_data = (state) => state?.commonReducer.tech_pending_data;
export const tech_pending_total_pages = (state) => state?.commonReducer.tech_pending_total_pages;
export const tech_pending_page_status = (state) => state?.commonReducer.tech_pending_page_status;
export const tech_pending_page = (state) => state?.commonReducer.tech_pending_page;
export const tech_pending_path = (state) => state?.commonReducer.tech_pending_path;
export const tech_closed_data = (state) => state?.commonReducer.tech_closed_data;
export const tech_closed_total_pages = (state) => state?.commonReducer.tech_closed_total_pages;
export const tech_closed_page_status = (state) => state?.commonReducer.tech_closed_page_status;
export const tech_closed_page = (state) => state?.commonReducer.tech_closed_page;
export const tech_closed_path = (state) => state?.commonReducer.tech_closed_path;
export const tech_request_details = (state) => state?.commonReducer.tech_request_details;
export const tech_detail_path = (state) => state?.commonReducer.tech_detail_path;
export const tech_request_report_data = (state) => state?.commonReducer.tech_request_report_data;
export const tech_report_total_pages = (state) => state?.commonReducer.tech_report_total_pages;
export const tech_report_page_status = (state) => state?.commonReducer.tech_report_page_status;
export const tech_report_page = (state) => state?.commonReducer.tech_report_page;
export const tech_report_openCount = (state) => state?.commonReducer.tech_report_openCount;
export const tech_report_closedCount = (state) => state?.commonReducer.tech_report_closedCount;
export const tech_report_loader = (state) => state?.commonReducer.tech_report_loader;
export const tech_detail_loader = (state) => state?.commonReducer.tech_detail_loader;
export const tech_archived_list = (state) => state?.commonReducer.tech_archived_list;
export const tech_archived_total_pages = (state) => state?.commonReducer.tech_archived_total_pages;
export const tech_archived_page_status = (state) => state?.commonReducer.tech_archived_page_status;
export const tech_archived_page = (state) => state?.commonReducer.tech_archived_page;
export const tech_archived_loader = (state) => state?.commonReducer.tech_archived_loader;
// export const tech_closed_path = (state) => state?.commonReducer.tech_closed_path;
// export const tech_closed_page = (state) => state?.commonReducer.tech_closed_page;
// export const tech_closed_path = (state) => state?.commonReducer.tech_closed_path;
// export const tech_closed_page = (state) => state?.commonReducer.tech_closed_page;
// export const tech_closed_path = (state) => state?.commonReducer.tech_closed_path;


export default commonSlice.reducer;